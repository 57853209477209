import { Comment } from "components/Comments";
import { useQuery } from "react-query";
import { serializeParams } from "utils/util";
import api from "./api";
import { simpleGet } from "./fetchers";
import { GenericResponse, User } from "./types";
import { CommentResponse } from "./user";

export enum AppraisalStatus {
  Scheduled = "scheduled",
  Confirmed = "confirmed",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum AppraisalCommentType {
  EmpGoal = "emp_goal",
  EmpComment = "emp_comment",
  ManagerGoal = "manager_goal",
  ManagerComment = "manager_comment",
}

export interface StaffAppraisalComment extends Comment {
  staffAppraisal: StaffAppraisal;
  staffAppraisalId: number;
  type: AppraisalCommentType; // emp_goal, emp_comment, manager_goal, manager_comment
  userId?: number;
}

export interface StaffAppraisalAnswer {
  questionId: number;
  answer: number;
}

export interface StaffAppraisal {
  id: number;
  userId: number;
  user: User;
  appraisalDate: string;
  appraisalCompletionDate: string;
  appraisalStartedDate: string;
  status: AppraisalStatus;
  answers: StaffAppraisalAnswer[];
  createdById: number;
  createdBy: User;
  managerSign?: string;
  managerSignAt?: Date;
  staffSign?: string;
  staffSignAt?: Date;
  assignedToStaff: boolean;
  assignedToStaffAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  staffAppraisalComments: StaffAppraisalComment[];
  appraisers: number[];
  appraiserDetails: User[];
}

export interface StaffAppraisalResponse
  extends Omit<GenericResponse, "status"> {
  appraisal: StaffAppraisal;
}

export interface StaffAppraisalQuestion {
  id: number;
  name: string;
}

export interface StaffAppraisalQuestionsResponse extends GenericResponse {
  appraisalQuestions: StaffAppraisalQuestion[];
}

export const appraisalBase = "/api/appraisal";

export const addAppraisalDate = async (data: any, staffId: number) => {
  return (await api.post<StaffAppraisalResponse>(
    `${appraisalBase}/admin/${staffId}`,
    data
  )) as StaffAppraisalResponse;
};

export const updateAppraisalDate = async (data: any, appraisalId: number) => {
  return (await api.put<StaffAppraisalResponse>(
    `${appraisalBase}/admin/${appraisalId}`,
    data
  )) as StaffAppraisalResponse;
};

export const updateAppraisers = async (data: any, appraisalId: number) => {
  return (await api.put<StaffAppraisalResponse>(
    `${appraisalBase}/admin/staff/appraisers/${appraisalId}`,
    data
  )) as StaffAppraisalResponse;
};

export function useGetAppraisal(appraisalId: number) {
  return useQuery<StaffAppraisal, Error>(
    `${appraisalBase}/admin/${appraisalId}`,
    simpleGet,
    {
      enabled: !!appraisalId, // Only fetch when appraisalId is present
      retry: false, // No retry on failure
    }
  );
}

export const getAppraisalDetails = async (appraisalId: number) => {
  return (await api.get<StaffAppraisalResponse>(
    `${appraisalBase}/user/by-id/${appraisalId}`
  )) as StaffAppraisalResponse;
};

export const confirmAppraisalDate = async (appraisalId: number) => {
  return (await api.put<StaffAppraisal>(
    `${appraisalBase}/admin/${appraisalId}/confirm`,
    { appraisalId }
  )) as StaffAppraisal;
};

export const startAppraisal = async (appraisalId: number) => {
  return (await api.put<StaffAppraisalResponse>(
    `${appraisalBase}/admin/${appraisalId}/start`,
    {
      appraisalId,
    }
  )) as StaffAppraisalResponse;
};

export function useGetAppraisalQuestions() {
  return useQuery<StaffAppraisalQuestionsResponse, Error>(
    `${appraisalBase}/questions`,
    simpleGet,
    {
      staleTime: Infinity,
    }
  );
}

export const answerAppraisalQuestion = async (
  data: any,
  appraisalId: number
) => {
  return (await api.put<StaffAppraisal>(
    `${appraisalBase}/admin/${appraisalId}/answer`,
    data
  )) as StaffAppraisal;
};

interface StaffAppraisalCommentsResponse {
  staffAppraisalComment: CommentResponse;
}

export const addAppraisalComment = async (data: any, appraisalId: number) => {
  return (await api.post<StaffAppraisalCommentsResponse>(
    `${appraisalBase}/admin/${appraisalId}/comment`,
    data
  )) as StaffAppraisalCommentsResponse;
};

export const editAppraisalComment = async (data: any, commentId: number) => {
  return (await api.put<StaffAppraisalCommentsResponse>(
    `${appraisalBase}/admin/comment/${commentId}`,
    data
  )) as StaffAppraisalCommentsResponse;
};

export const deleteAppraisalComment = async (commentId: number) => {
  return (await api.delete<CommentResponse>(
    `${appraisalBase}/admin/comment/${commentId}`
  )) as CommentResponse;
};

export const signAppraisal = async (data: any, appraisalId: number) => {
  return (await api.put<StaffAppraisalResponse>(
    `${appraisalBase}/user/${appraisalId}/sign`,
    data
  )) as StaffAppraisalResponse;
};

export const assignAppraisalToStaff = async (
  data: any,
  appraisalId: number
) => {
  return (await api.put<StaffAppraisalResponse>(
    `${appraisalBase}/admin/${appraisalId}/assign-to-staff`,
    data
  )) as StaffAppraisalResponse;
};

interface StaffAppraisalsResponse {
  appraisals: StaffAppraisal[];
  count: number;
}

export interface StaffAppraisalFilters {
  [k: string]: string;
}

export const fetchStaffAppraisals = async (queryParams: string) => {
  return (await api.get<StaffAppraisalsResponse>(
    `${appraisalBase}/admin/list?${queryParams}`
  )) as StaffAppraisalsResponse;
};

export function useGetStaffAppraisals(filters: Partial<StaffAppraisalFilters>) {
  const queryParams = serializeParams(filters);
  return useQuery<StaffAppraisalsResponse, Error>(
    [`${appraisalBase}/admin/list`, queryParams],
    () => fetchStaffAppraisals(queryParams),
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export function useGetLatestAppraisal(staffId: number) {
  return useQuery<StaffAppraisalResponse, Error>(
    `${appraisalBase}/admin/latest/${staffId}`,
    simpleGet,
    {
      enabled: !!staffId, // Only fetch when appraisalId is present
      staleTime: Infinity,
      retry: 1, // No retry on failure
    }
  );
}

export const fetchUserAppraisals = async (queryParams: string) => {
  return (await api.get<StaffAppraisalsResponse>(
    `${appraisalBase}/user/list?${queryParams}`
  )) as StaffAppraisalsResponse;
};

export function useGetUserAppraisals(filters: Partial<StaffAppraisalFilters>) {
  const queryParams = serializeParams(filters);
  return useQuery<StaffAppraisalsResponse, Error>(
    [`${appraisalBase}/user/list`, queryParams],
    () => fetchUserAppraisals(queryParams),
    {
      staleTime: Infinity,
      retry: 1,
    }
  );
}

export function useGetUserLatestAppraisal() {
  return useQuery<StaffAppraisalResponse, Error>(
    `${appraisalBase}/user/latest`,
    simpleGet,
    {
      staleTime: Infinity,
      retry: 1, // No retry on failure
    }
  );
}
