import classNames from "classnames";
import React, { InputHTMLAttributes, useEffect } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { get } from "utils/util";
import { FormField } from "./types";
import { renderErrorMessage } from "./utility";

interface CheckboxProps
  extends FormField,
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "type" | "name" | "defaultValue"
    > {
  label: string;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  additionalText?: string;
  labelClassName?: string;
  additionalTextClassName?: string;
  containerClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  requiredCondition,
  label,
  className,
  onChange,
  defaultValue,
  isDisabled,
  additionalText,
  labelClassName,
  additionalTextClassName,
  isHiglighted,
  containerClassName,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();
  useEffect(() => {
    if (isDisabled) {
      setValue(name, defaultValue || false); // Retain the value when disabled
    }
  }, [isDisabled, name, defaultValue, setValue]);
  const stateValue = watch(name);
  return (
    <div className={classNames("", className)}>
      <div
        className={classNames(
          "flex space-x-3",
          additionalText ? "items-start" : "items-center",
          containerClassName
        )}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              {...props}
              id={name}
              type="checkbox"
              className={classNames(
                "form-checkbox text-primary-500 border-gray-300 active:ring-primary-500 focus:ring-primary-500 rounded disabled:text-gray-300 disabled:ring-gray-300",
                {
                  "!ring-red-600": get(errors, name),
                  "!focus:ring-red-600": get(errors, name),
                  "!border-red-600": get(errors, name),
                  "!border:ring-red-600": get(errors, name),
                },
                isHiglighted &&
                  requiredCondition &&
                  !stateValue &&
                  "border-secondary-500",
                additionalText ? "mt-1" : ""
              )}
              {...register(name, {
                required: requiredCondition,
              })}
              onChange={onChange ? onChange : field.onChange}
              defaultChecked={defaultValue as boolean}
              disabled={isDisabled}
            />
          )}
        />

        {label && (
          <div className="flex flex-col items-start">
            <label
              htmlFor={name}
              className={classNames(
                "text-sm leading-5 font-medium text-gray-900",
                labelClassName,
                isDisabled && "!text-gray-400 font-normal"
              )}
            >
              {label}
            </label>
            <p
              className={classNames(
                "text-gray-500 text-sm font-normal",
                isDisabled && "!text-gray-400",
                additionalTextClassName
              )}
            >
              {additionalText}
            </p>
          </div>
        )}
      </div>
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default Checkbox;
