import axios from "axios";
import { baseURL, getToken } from "../api";

export const uploadFile = async (
  file: File,
  onProgress: any,
  isStatic = false
) => {
  // Validate if the file is empty
  if (file.size === 0) {
    throw new Error("File is empty. Please upload a valid file.");
  }

  const postData = {
    name: file.name,
    type: file.type,
    isStatic,
  };
  const url = `${baseURL}/api/file`;
  const res = await axios({
    url,
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: "json",
    data: JSON.stringify(postData),
  });
  const data = res.data.fields;
  data.file = file;
  const formData = new FormData();
  for (const name in data) {
    formData.append(name, data[name]);
  }
  formData.append("Content-Type", data.file.type);
  const awsResponse = await axios({
    url: res.data.url,
    method: "post",
    headers: {
      Accept: "*/*",
    },
    data: formData,
    onUploadProgress: (event) => {
      if (onProgress && typeof onProgress === "function") {
        onProgress(event);
      }
    },
  });
  return { awsResponse, key: data.key };
};

export const downloadFile = async (key: string) => {
  const requestURL = `${baseURL}/api/file?key=${key}`;
  const res = await axios({
    url: requestURL,
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
  const { url } = res.data;
  window.open(url, "_blank");
};
