import classNames from "classnames";
import { enGB } from "date-fns/locale";
import React from "react";
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale,
} from "react-datepicker";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { get } from "utils/util";
import Button from "./Button";
import { Icon } from "./Icon";
import Select, { Option } from "./Select";
import { FieldType, FormField } from "./types";
import { renderErrorMessage } from "./utility";

registerLocale("enGB", enGB); // Set the locale for DatePicker

const CustomDatePickerHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  changeMonth,
  changeYear,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="bg-white flex justify-between items-center mb-5">
      <Button variant="icon" onClick={decreaseMonth} className="!p-0">
        <Icon name="leftArrow" />
      </Button>
      <div className="bg-white flex justify-center items-center w-full relative">
        <Select
          options={Array.from({ length: 12 }, (_, index) => {
            const month = new Date(2000, index).toLocaleString("en-GB", {
              month: "long",
            });
            return {
              label: month,
              value: index,
            };
          })}
          label=""
          type={FieldType.Select}
          defaultValue={date.getMonth()}
          name="calendarMonth"
          onChange={(option: Option) =>
            changeMonth(parseInt(option.value as string))
          }
          containerClassName="!w-36 !mt-0"
          inputClassName="!w-4/5"
        />
        <Select
          options={Array.from({ length: 80 }, (_, index) => {
            const startingYear = 1970;
            return {
              label: (startingYear - 10 + index).toString(),
              value: startingYear - 10 + index,
            };
          })}
          label=""
          type={FieldType.Select}
          name="calendarYear"
          defaultValue={date.getFullYear()}
          onChange={(option: Option) =>
            changeYear(parseInt(option.value as string))
          }
          containerClassName="!w-24 ml-1  !mt-0"
          inputClassName="!w-3/4"
        />
      </div>
      <Button variant="icon" onClick={increaseMonth} className=" !p-0">
        <Icon name="rightArrow" />
      </Button>
    </div>
  );
};

interface CalendarProps extends FormField {
  name: string;
  label: string;
  isDateOnly?: boolean;
  maxDate?: Date;
  minDate?: Date;
  showTimeInput?: boolean;
  customDateFormat?: string;
  containerClassName?: string;
  validateFunction?: (date: Date) => boolean | string;
  wrapperClassName?: string;
  showOnlyWeekday?: boolean;
  showWorkingTime?: boolean;
  showWorkingDays?: boolean;
  calendarDateSelected?: string;
}

const Calendar: React.FC<CalendarProps> = ({
  name,
  label,
  className,
  requiredCondition,
  parentFormContainerClassName,
  defaultValue,
  isDisabled,
  isDateOnly = true,
  maxDate,
  showTimeInput,
  minDate,
  customDateFormat = "dd/MM/yyyy",
  validateFunction,
  containerClassName,
  wrapperClassName,
  placeholder,
  showOnlyWeekday = false,
  showWorkingTime = false,
  isHiglighted = false,
  showWorkingDays = false,
  calendarDateSelected,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    setError,
    watch,
  } = useFormContext();

  const stateValue = watch(name);

  // Function to filter out weekends
  const filterWeekdays = (date: Date) => {
    const day = date.getDay();
    if (showWorkingDays) {
      return day !== 0 && day !== 6; // Weekday: Monday to Friday (0 is Sunday, 6 is Saturday) EIC-245
    }
    return day !== 0 && day !== 6 && day !== 2 && day !== 3; // Weekday: Monday to Friday (0 is Sunday, 6 is Saturday, 2 is Tuesday and 3 is Wednesday) EIC-257
  };

  // Function to filter time
  const filterTime = (time: Date) => {
    const hour = time.getHours();
    const minutes = time.getMinutes();
    if (calendarDateSelected) {
      const dateSelected = getValues(calendarDateSelected);
      if (!dateSelected) {
        return false;
      }
      const now = new Date();
      const selectedDate = new Date(getValues(calendarDateSelected));
      if (
        minDate &&
        minDate.toDateString() === selectedDate.toDateString() &&
        (hour < now.getHours() ||
          (hour === now.getHours() && minutes < now.getMinutes()))
      ) {
        return false;
      }
      // If minDate is set and today, disable past hours
      if (
        minDate &&
        minDate.toDateString() === now.toDateString() &&
        selectedDate.toDateString() === now.toDateString()
      ) {
        return hour >= now.getHours() && hour >= 8 && hour < 17;
      }
      return hour >= 8 && hour < 17;
    }
    return hour >= 10 && hour < 16; // Allow only times between 10 AM and 4 PM  EIC-257
  };

  const customDayClassNames = (
    date: Date,
    maxDate: Date | undefined,
    minDate: Date | undefined
  ) => {
    const currentDate = new Date();
    const isToday =
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear();
    const isDisabled =
      (maxDate && date > maxDate) ||
      (minDate && date < minDate) ||
      (showOnlyWeekday &&
        (date.getDay() === 0 ||
          date.getDay() === 6 ||
          date.getDay() === 2 ||
          date.getDay() === 3)) ||
      (showWorkingDays && (date.getDay() === 0 || date.getDay() === 6));

    if (isDisabled) {
      return "bg-gray-50 text-gray-400";
    } else if (isToday) {
      return "text-primary-600 font-semibold bg-transparent";
    } else {
      return "bg-transparent text-gray-700";
    }
  };

  const renderDayContents = (dayOfMonth: number, date?: Date) => {
    const currentDate = getValue(getValues(name)) || new Date();
    const isSelected =
      date?.getDate() === currentDate.getDate() &&
      date?.getMonth() === currentDate.getMonth();
    let classname = "";
    const isDisabled =
      (maxDate && date && date > maxDate) ||
      (minDate && date && date < minDate) ||
      (showOnlyWeekday &&
        (currentDate.getDay() === 0 ||
          currentDate.getDay() === 6 ||
          currentDate.getDay() === 2 ||
          currentDate.getDay() === 3)) ||
      (showWorkingDays &&
        (currentDate.getDay() === 0 || currentDate.getDay() === 6));

    if (isSelected && !isDisabled) {
      classname = "bg-primary-200 text-primary-900 rounded-full font-semibold";
    }

    return (
      <div
        className={classNames(
          "customDay hover:bg-primary-200 hover:text-primary-900 hover:rounded-full hover:font-semibold",
          classname
        )}
      >
        {dayOfMonth}
      </div>
    );
  };

  const getValue = (fieldValue: string | Date) => {
    if (fieldValue && typeof fieldValue === "string") {
      return new Date(fieldValue);
    } else if (fieldValue) {
      return fieldValue as Date;
    } else if (defaultValue) {
      return new Date(defaultValue as string);
    }
    return undefined;
  };

  return (
    <div className={classNames("mt-5", containerClassName)} key={name}>
      {label && (
        <label
          className={classNames(
            "block text-sm font-semibold leading-6 text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: requiredCondition }}
        defaultValue={getValue(defaultValue as Date)}
        render={({ field }) => {
          return (
            <>
              <DatePicker
                {...field}
                id={name}
                selected={getValue(field.value)}
                filterDate={
                  showOnlyWeekday || showWorkingDays
                    ? filterWeekdays
                    : undefined
                }
                filterTime={showWorkingTime ? filterTime : undefined}
                placeholderText={placeholder || "Select date"}
                onChange={(date) => {
                  field.onChange(date);
                  if (validateFunction) {
                    const validationMessage = validateFunction(date as Date);
                    if (validationMessage === true) {
                      clearErrors(name);
                    } else {
                      setError(name, {
                        type: "manual",
                        message: validationMessage as string,
                      });
                    }
                  }
                }}
                onBlur={field.onBlur}
                dateFormat={showTimeInput ? "h:mm aa" : customDateFormat}
                timeFormat="h:mm aa"
                locale="enGB"
                wrapperClassName={wrapperClassName}
                calendarClassName="datePicker border border-gray-200 shadow rounded-md p-3 text-sm placeholder:text-gray-900"
                renderCustomHeader={(props) => (
                  <CustomDatePickerHeader {...props} />
                )}
                dayClassName={(date: Date) =>
                  customDayClassNames(date, maxDate, minDate)
                }
                renderDayContents={renderDayContents}
                ref={(inputRef) => {
                  if (inputRef) {
                    field.ref({ focus: inputRef.setFocus });
                  }
                }}
                icon={
                  <>
                    <div className="absolute top-4 left-2 flex items-center pr-3 pointer-events-none">
                      {showTimeInput ? (
                        <Icon
                          name="clock"
                          size={16}
                          className={classNames(
                            isDisabled && "!text-gray-400 !border-gray-300",
                            "text-gray-400"
                          )}
                        />
                      ) : (
                        <Icon
                          name="calendar"
                          size={16}
                          className={classNames(
                            isDisabled && "!text-gray-400 !border-gray-300",
                            "text-gray-400"
                          )}
                        />
                      )}
                    </div>
                    <div className="absolute inset-y-2 right-0 flex items-center pr-3 pointer-events-none">
                      <Icon
                        name="downChevron"
                        className={classNames(
                          isDisabled && "!text-gray-400 !border-gray-300",
                          "text-gray-400"
                        )}
                        size={20}
                      />
                    </div>
                  </>
                }
                showIcon={true}
                disabled={isDisabled}
                maxDate={maxDate}
                minDate={minDate}
                showTimeSelect={showTimeInput}
                showTimeSelectOnly={showTimeInput}
                autoComplete="off"
                onKeyDown={(e) => e.preventDefault()}
                customInput={
                  <input
                    name="calendarInput"
                    onBeforeInput={(e) => e.preventDefault()}
                    ref={field.ref}
                    placeholder={placeholder || "Select date"}
                    className={classNames(
                      "block h-10 rounded-md border-0 p-2 mt-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 sm:text-sm sm:leading-6  text-gray-900",
                      "focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow ",
                      {
                        "!ring-red-600": get(errors, name),
                        "!focus:ring-red-600": get(errors, name),
                      },
                      "disabled:text-gray-400 disabled:border-gray-300 disabled:placeholder:text-gray-400",
                      className ? className : "!w-96",
                      "!caret-transparent placeholder:text-gray-900 pl-4",
                      isHiglighted &&
                        requiredCondition &&
                        !stateValue &&
                        "ring-secondary-500"
                    )}
                  />
                }
              />
            </>
          );
        }}
      />
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default Calendar;
