import { useGetLatestAppraisal } from "api/annualAppraisal";
import { User } from "api/types";
import { Icon } from "components/Icon";
import { Loading } from "components/Loading";
import { FormData } from "components/types";
import { FormProvider, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import AnnualAppraisal from "./AnnualAppraisal";

interface AnnualAppraisalContainerProps {
  user: User;
  admin: User;
  isSuperAdmin: boolean;
}

export default function AnnualAppraisalContainer({
  user,
  admin,
  isSuperAdmin,
}: AnnualAppraisalContainerProps) {
  const { data, isLoading, error } = useGetLatestAppraisal(user.id);
  const formMethods1 = useForm<FormData>({
    defaultValues: { answers: {} },
  });
  if (isLoading || !data) {
    return <Loading />;
  }

  if (error) {
    toast.error(error?.message || "Error occurred", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="error" />,
    });
  }

  return (
    <FormProvider {...formMethods1}>
      <AnnualAppraisal
        user={user}
        admin={admin}
        appraisal={data?.appraisal}
        formMethods1={formMethods1}
        isSuperAdmin={isSuperAdmin}
      />
    </FormProvider>
  );
}
