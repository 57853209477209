import {
  StaffAppraisalQuestion,
  useGetAppraisalQuestions,
} from "api/annualAppraisal";
import classNames from "classnames";
import { renderErrorMessage } from "components/utility";
import React, { useEffect, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { get } from "utils/util";

export const expectationsMap = {
  1: "Meets some expectations",
  2: "Consistently meets expectations",
  3: "Exceed expectations",
};

const AppraisalTable: React.FC = () => {
  const [appraisalQuestions, setAppraisalQuestions] =
    useState<StaffAppraisalQuestion[]>();

  const { data } = useGetAppraisalQuestions();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (data) {
      setAppraisalQuestions(data.appraisalQuestions);
    }
  }, [data]);

  return (
    <div className="my-5 rounded-lg">
      <div className="overflow-x-auto shadow rounded-lg">
        <table className="w-full ">
          <thead className=" w-full">
            <tr className="bg-gray-50 text-gray-700 border-b border-gray-200">
              <th className="text-xs leading-4 font-medium tracking-wider uppercase px-6 py-3 text-left">
                APPRAISAL CRITERIA
              </th>
              <th className="text-xs leading-4 font-medium tracking-wider uppercase px-6 py-3">
                MEETS SOME EXPECTATIONS
              </th>
              <th className="text-xs leading-4 font-medium tracking-wider uppercase px-6 py-3">
                CONSISTENTLY MEETS EXPECTATIONS
              </th>
              <th className="text-xs leading-4 font-medium tracking-wider uppercase px-6 py-3">
                EXCEED EXPECTATIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {appraisalQuestions &&
              appraisalQuestions.map((row, rowIndex) => {
                return (
                  <>
                    <tr
                      key={rowIndex}
                      className={classNames(
                        "hover:bg-gray-50 border-b border-gray-200 group-hover:bg-gray-50",
                        Boolean(get(errors, `answers.${row.id}`)) && "!border-0"
                      )}
                    >
                      <td className="p-3 text-sm leading-5 font-medium px-6 py-3">
                        {row.name}
                      </td>
                      {[1, 2, 3].map((answer) => {
                        return (
                          <td key={answer} className="p-3 text-center">
                            <Controller
                              name={`answers.${row.id}`}
                              control={control}
                              rules={{
                                required: "Please select one of the values",
                              }}
                              render={({ field }) => (
                                <input
                                  id={`answers.${row.id}`}
                                  type="checkbox"
                                  className={classNames(
                                    "form-checkbox text-primary-500 border-gray-300 active:ring-primary-500 focus:ring-primary-500 rounded disabled:text-gray-300 disabled:ring-gray-300",
                                    {
                                      "!ring-red-600": get(
                                        errors,
                                        `answers.${row.id}`
                                      ),
                                      "!focus:ring-red-600": get(
                                        errors,
                                        `answers.${row.id}`
                                      ),
                                      "!border-red-600": get(
                                        errors,
                                        `answers.${row.id}`
                                      ),
                                      "!border:ring-red-600": get(
                                        errors,
                                        `answers.${row.id}`
                                      ),
                                    }
                                  )}
                                  checked={
                                    watch(`answers.${row.id}`) === answer
                                  }
                                  onChange={() =>
                                    setValue(`answers.${row.id}`, answer)
                                  }
                                />
                              )}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    {get(errors, `answers.${row.id}`) && (
                      <tr className="border-b border-gray-200  group-hover:bg-gray-50">
                        <td colSpan={4} className="text-center">
                          {renderErrorMessage(
                            get(errors, `answers.${row.id}`) as FieldError
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppraisalTable;
