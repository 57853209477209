import { AppraisalStatus, fetchStaffAppraisals } from "api/annualAppraisal";
import Button from "components/Button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useOutletContext } from "react-router-dom";
import { DashboardStaffOutletContext } from "routes/reports";
import {
  formatDateWithDay,
  formatDateWithTime,
  serializeParams,
} from "utils/util";
import AnnualAppraisalHistory from "./AnnualAppraisalHistory";

export default function ScheduledAnnualAppraisal() {
  const { user, isSuperAdmin } =
    useOutletContext<DashboardStaffOutletContext>();
  const exportPDF = async () => {
    const doc = new jsPDF();
    const queryParams = serializeParams({
      report: "yes",
      order_by: "date",
      order: "asc",
      filter_status: AppraisalStatus.Confirmed,
    });
    const response = await fetchStaffAppraisals(queryParams);

    // Add a heading
    doc.setFontSize(16); // Set font size for the heading
    doc.text(
      "Scheduled Annual Appraisal",
      doc.internal.pageSize.width / 2,
      10,
      {
        align: "center",
      }
    );

    const tableRows: any[] = [];

    (response?.appraisals || []).forEach((item) => {
      //const rowData = columns.filter((col) => col.accessor !== 'document').map(col => item[col.accessor]);
      const rowData = [
        `${item.user.title} ${item.user.firstName} ${item.user.lastName}`,
        `${item.user.staffProfile.occupation}`,
        `${item.user.mobile || "N/A"}`,
        formatDateWithTime(new Date(item.appraisalDate)),
      ];
      tableRows.push(rowData);
    });

    autoTable(doc, {
      head: [["Appraised by", "Occupation", "Phone number", "Appraisal date"]],
      body: tableRows,
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 50 },
        2: { cellWidth: 30 },
        3: { cellWidth: 60 },
      },
      margin: { left: 5, top: 5 },
      styles: { halign: "center" },
      tableWidth: "wrap",
      startY: 15, // Start the table below the heading
    });

    doc.save(
      `scheduled_appraisal_${formatDateWithDay(new Date()).replaceAll(" ", "_")}.pdf`
    );
  };
  return (
    <div className="py-1">
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl flex-1">Scheduled Annual Appraisal</h1>
          <div className="flex items-center justify-center space-x-2">
            <Button variant="primary" size="base" onClick={() => exportPDF()}>
              Export to PDF
            </Button>
          </div>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl pt-8">
          <AnnualAppraisalHistory admin={user} isSuperAdmin={isSuperAdmin} />
        </div>
      </main>
    </div>
  );
}
