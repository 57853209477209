import Button from "components/Button";
import Calendar from "components/Calendar";
import Modal from "components/Modal";
import { FieldType, FormData } from "components/types";
import { format } from "date-fns-tz";
import { UseFormReturn } from "react-hook-form";
import { getTodayDate } from "utils/util";

interface AnnualAppraisalSchedulePopupProps {
  setShowAnnualAppraisalDateModal: (
    showAnnualAppraisalDateModal: boolean
  ) => void;
  showAnnualAppraisalDateModal: boolean;
  formMethods1: UseFormReturn<FormData, any, undefined>;
  staffId: number;
  annualAppraisalDate: Date | null;
  setAnnualAppraisalDate: (date: string) => void;
  appraisalId: number;
}

const AnnualAppraisalSchedulePopup: React.FC<
  AnnualAppraisalSchedulePopupProps
> = ({
  setShowAnnualAppraisalDateModal,
  showAnnualAppraisalDateModal,
  formMethods1,
  staffId,
  annualAppraisalDate,
  setAnnualAppraisalDate,
  appraisalId,
}: AnnualAppraisalSchedulePopupProps) => {
  const submitAppraisalDate = async () => {
    const annualAppraisalDate = formMethods1.getValues("annualAppraisalDate");
    const annualAppraisalTime = formMethods1.getValues("annualAppraisalTime");
    const appraisalDate = new Date(annualAppraisalDate);
    appraisalDate.setHours(annualAppraisalTime.getHours());
    appraisalDate.setMinutes(annualAppraisalTime.getMinutes());
    appraisalDate.setSeconds(annualAppraisalTime.getSeconds());
    setAnnualAppraisalDate(
      format(appraisalDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
        timeZone: "Australia/Sydney",
      })
    );
    setShowAnnualAppraisalDateModal(false);
  };

  return (
    <Modal
      isOpen={showAnnualAppraisalDateModal}
      onClose={() => setShowAnnualAppraisalDateModal(false)}
      modalHeader=""
      modalBodyClassName="!mt-0"
      customModalStyles={{ maxWidth: "610px", width: "610px" }}
    >
      <h3 className="text-gray-900 text-lg leading-6 font-semibold px-6 pt-2 pb-4 border-b border-gray-300">
        Select date and time for annual appraisal
      </h3>
      <div className="p-6 flex items-center justify-between space-x-2">
        <Calendar
          name="annualAppraisalDate"
          label="Date"
          type={FieldType.Calendar}
          minDate={getTodayDate()}
          containerClassName="w-2/3 inline-block align-top !my-0 pr-6"
          className="w-full"
          wrapperClassName="w-full"
          showWorkingDays
          defaultValue={annualAppraisalDate || undefined}
        />
        <Calendar
          name="annualAppraisalTime"
          label="Time"
          type={FieldType.Calendar}
          showTimeInput
          minDate={getTodayDate()}
          showWorkingTime
          placeholder="Select time"
          containerClassName="w-1/3 inline-block align-top !my-0 timeOnlyPicker"
          className="w-full"
          wrapperClassName="w-full"
          defaultValue={annualAppraisalDate || undefined}
          calendarDateSelected="annualAppraisalDate"
        />
      </div>
      <div className="flex justify-end items-center  rounded-b-md px-4 py-3  rounded-b-md space-x-2 border-t border-gray-300">
        <Button
          variant="white"
          onClick={() => setShowAnnualAppraisalDateModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="text-primary-800"
          onClick={() => {
            submitAppraisalDate();
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
export default AnnualAppraisalSchedulePopup;
