import DataTableContainer from "components/DataTableContainer";
import Notification from "components/Notification";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { getEssentialDocumentData } from "routes/MyAccount/fields";
import { DashboardStaffOutletContext } from "routes/reports";

export const StaffEssentialDocuments: React.FC = () => {
  const { user } = useOutletContext<DashboardStaffOutletContext>();

  return (
    <div>
      {[
        {
          title: "Essential Documents",
          data: getEssentialDocumentData(user),
          heading: (
            <Notification
              type="info"
              message="Organise these documents beforehand so that you don’t lose access to your staff account when the documents expire."
              className="!m-0"
            />
          ),
          sectionId: "EssentialDocuments",
        },
      ].map((detail) => (
        <DataTableContainer
          title={detail.title}
          heading={detail.heading || <></>}
          data={detail.data}
          key={detail.title}
          sectionId={detail.sectionId}
          parentContainerClassName="!mt-0"
        />
      ))}
    </div>
  );
};
