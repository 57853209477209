import {
  AppraisalStatus,
  StaffAppraisal,
  useGetStaffAppraisals,
} from "api/annualAppraisal";
import { User } from "api/types";
import classNames from "classnames";
import Badge, { BadgeProps } from "components/Badge";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import SortableTable, { Column, SortOptions } from "components/SortableTable";
import { getAvatarName } from "components/StaffDetail";
import { FormData } from "components/types";
import "jspdf-autotable";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, formatDateWithTime } from "utils/util";
import AnnualAppraisalContent from "./AnnualAppraisalContent";

export const handleViewProfile = (userId: number) => {
  const url = `/staff/view-profile/${userId}`;
  window.open(url, "_blank")?.focus();
};

export interface AnnualAppraisalHistoryFilters {
  [k: string]: string;
}

const fieldToEndpointMapping = {
  status: "status",
  appraisalDate: "date",
  appraisers: "name",
};

export const getAppraisalStatus = (
  status: AppraisalStatus,
  additionalClassName?: string
): BadgeProps => {
  switch (status) {
    case AppraisalStatus.Completed:
      return {
        iconName: "check",
        message: capitalizeFirstLetter(status),
        className: classNames(
          "bg-green-100 text-green-800 ",
          additionalClassName
        ),
      };
    case AppraisalStatus.Confirmed:
      return {
        iconName: "solidCheck",
        message: capitalizeFirstLetter(status),
        className: classNames(
          "bg-purple-100 text-purple-800",
          additionalClassName
        ),
        iconClassName: "text-purple-800",
        buttonClassName: "-mt-1",
        size: 12,
      };
    case AppraisalStatus.InProgress:
      return {
        iconName: "inProgress",
        message: capitalizeFirstLetter(status).split("_").join(" "),
        className: classNames(
          "bg-blue-100 text-blue-800 ",
          additionalClassName
        ),
        iconClassName: "text-blue-800",
        size: 12,
      };
    case AppraisalStatus.Scheduled:
      return {
        iconName: "invited",
        message: capitalizeFirstLetter(status),
        className: classNames(
          "bg-pink-100 text-pink-800 ",
          additionalClassName
        ),
      };
    default:
      return {
        iconName: "solidCheck",
        message: status,
        className: classNames(
          "bg-purple-100 text-purple-800 ",
          additionalClassName
        ),
        iconClassName: "text-purple-800",
        buttonClassName: "-mt-1",
        size: 12,
      };
  }
};

interface AnnualAppraisalHistoryProps {
  staffId?: number;
  isStaffView?: boolean;
  currentAppraisal?: StaffAppraisal;
  admin?: User;
  isSuperAdmin?: boolean;
}

export default function AnnualAppraisalHistory({
  staffId,
  isStaffView = false,
  currentAppraisal,
  isSuperAdmin,
  admin,
}: AnnualAppraisalHistoryProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const defaultFilter: Partial<AnnualAppraisalHistoryFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [isError, setIsError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<StaffAppraisal | null>(null);
  const [sortConfig, setSortConfig] =
    useState<SortOptions<StaffAppraisal> | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };
  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const getFilters = () => {
    let responseFilters = filters;
    if (staffId) {
      responseFilters = {
        ...filters,
        filter_user: staffId?.toString(),
        filter_status: [
          AppraisalStatus.InProgress,
          AppraisalStatus.Completed,
        ].join(","),
      };
    }
    if (!isStaffView) {
      responseFilters = {
        ...filters,
        filter_status: AppraisalStatus.Confirmed,
      };
    }
    return responseFilters;
  };

  const [documents, setDocuments] = useState<StaffAppraisal[]>([]);
  const { data, error } = useGetStaffAppraisals(getFilters());

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.appraisals) && data.appraisals) {
        const documents =
          isSuperAdmin || !isStaffView
            ? data.appraisals
            : data.appraisals.filter(
                (app) =>
                  admin &&
                  app.appraisers &&
                  app.appraisers.indexOf(admin.id) > -1
              );
        setDocuments([...documents]);
        setTotalItems(data.count || 1);
        return;
      }
      setIsError(true);
    }
  }, [data, admin, isSuperAdmin, isStaffView]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: currentPage.toString(),
        take: itemsPerPage.toString(),
        order_by:
          fieldToEndpointMapping[
            sortConfig.sortField as keyof typeof fieldToEndpointMapping
          ],
        order: sortConfig.sortOrder,
      });
    }
  }, [sortConfig, currentPage, itemsPerPage]);

  const columns: Column<StaffAppraisal>[] = [
    {
      header: "Appraised by",
      accessor: "appraisers",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) =>
        row.appraiserDetails && row.appraiserDetails.length > 0 ? (
          <div className="flex items-center justify-start space-x-1 cursor-pointer w-full pr-1">
            {row.appraiserDetails.map((appraiser, index) => (
              <React.Fragment key={appraiser.id}>
                <div
                  className="flex items-center space-x-1 cursor-pointer overflow-hidden"
                  style={{ maxWidth: "calc(100% - 1rem)" }} // Adjust max-width to fit your layout
                  onClick={() => handleViewProfile(appraiser.id)}
                >
                  <Avatar
                    name={getAvatarName(appraiser as User)}
                    size="32"
                    round
                    textSizeRatio={2.28}
                    className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                    color="#D1F5FC"
                    fgColor="#194860"
                  />
                  <span className="truncate text-sm leading-5 font-semibold text-gray-900">
                    {appraiser.firstName} {appraiser.lastName}
                  </span>
                </div>
                {index !== row.appraiserDetails.length - 1 && (
                  <span className="text-gray-400">, </span>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <></>
        ),
      columnWidth: "35%",
    },
    {
      header: "Review date and time",
      accessor: "appraisalDate",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {formatDateWithTime(new Date(row.appraisalStartedDate as string))}
        </span>
      ),
      columnWidth: "30%",
    },
    {
      header: "Status",
      accessor: "status",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) => (
        <div className="text-gray-500 inline-flex">
          <Badge {...getAppraisalStatus(value as AppraisalStatus)} />
        </div>
      ),
      columnWidth: "25%",
    },
    {
      header: "",
      accessor: "id",
      Cell: (value: any, row: StaffAppraisal) => (
        <Button
          variant="text"
          className="w-3/4 ml-2"
          onClick={() => {
            setIsDrawerOpen(true);
            setSelectedDocument(row);
          }}
        >
          View
        </Button>
      ),
      columnWidth: "10%",
    },
  ];

  const scheduledColumns: Column<StaffAppraisal>[] = [
    {
      header: "Name",
      accessor: "user",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) =>
        row.createdBy ? (
          <div
            className="flex items-center justify-start space-x-4 cursor-pointer w-full pr-1"
            onClick={() => handleViewProfile(row.user.id)}
          >
            <Avatar
              name={getAvatarName(row.user as User)}
              size="32"
              round
              textSizeRatio={2.28}
              className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
              color="#D1F5FC"
              fgColor="#194860"
              src={row.user.avatar}
            />
            <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full">{`${row.user.firstName} ${row.user.lastName}`}</span>
          </div>
        ) : (
          <></>
        ),
      columnWidth: "25%",
    },
    {
      header: "Occupation",
      accessor: "user",
      Cell: (value: any, row: StaffAppraisal) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {row.user.staffProfile.occupation}
        </span>
      ),
      columnWidth: "25%",
    },
    {
      header: "Phone number",
      accessor: "user",
      Cell: (value: any, row: StaffAppraisal) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {row.user.mobile}
        </span>
      ),
      columnWidth: "15%",
    },
    {
      header: "appraisal date and time",
      accessor: "appraisalDate",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {formatDateWithTime(new Date(row.appraisalDate as string))}
        </span>
      ),
      columnWidth: "20%",
    },
    {
      header: "",
      accessor: "id",
      Cell: (value: any, row: StaffAppraisal) => (
        <Button
          variant="text"
          className="w-3/4 ml-2"
          onClick={() => {
            navigate(`/staff/view-profile/${row.user.id}?tab=3`);
          }}
        >
          Start appraisal
        </Button>
      ),
      columnWidth: "15%",
    },
  ];

  if (isError || error) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }

  if (
    documents.length === 0 ||
    (documents.length === 1 &&
      currentAppraisal &&
      currentAppraisal.id === documents[0].id &&
      !currentAppraisal.assignedToStaff)
  ) {
    return isStaffView ? <></> : <>No records found</>;
  }

  return (
    <div
      className={classNames(
        "py-1",
        isStaffView ? "bg-white shadow rounded-md mt-5 p-6" : ""
      )}
    >
      {isStaffView && (
        <h1 className="text-lg font-semibold leading-8 text-gray-900 pb-1 mb-3 pt-3">
          Annual Appraisal History
        </h1>
      )}
      <main className="mb-5">
        <div className="">
          <div ref={componentRef}>
            <SortableTable
              columns={isStaffView ? columns : scheduledColumns}
              data={[...documents]}
              setSortConfig={setSortConfig}
              sortConfig={sortConfig}
              containerClassName="!shadow-none rounded-t-md border-b-0"
            />
          </div>
          {selectedDocument && (
            <RightDrawer
              isOpen={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
                setSelectedDocument(null);
              }}
              title={`Appraisal detaills`}
              childrenContainerClassName="!p-0"
              additionalHeaderContainerClassName="flex-col !items-start"
              additionalHeaderInformation={
                <div className="flex flex-col items-start w-full">
                  <div className="flex items-center justify-between  w-1/2 mt-5">
                    <p>
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        created date:
                      </span>
                      <span className="text-sm leading-5 font-medium">
                        {selectedDocument.appraisalStartedDate
                          ? formatDateWithTime(
                              new Date(selectedDocument.appraisalStartedDate)
                            )
                          : "N/A"}
                      </span>
                    </p>
                    <p>
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        completed date:
                      </span>
                      <span className="text-sm leading-5 font-medium">
                        {selectedDocument.appraisalCompletionDate
                          ? formatDateWithTime(
                              new Date(selectedDocument.appraisalCompletionDate)
                            )
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full mt-2">
                    <p className="flex items-center justify-between space-x-2">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Appraised by:
                      </span>
                      <div className="text-sm leading-5 font-medium flex items-center space-x-1">
                        {(selectedDocument.appraiserDetails || []).map(
                          (appraiser, index) => (
                            <React.Fragment key={appraiser.id}>
                              <div
                                className="flex items-center justify-start space-x-1 cursor-pointer"
                                onClick={() => handleViewProfile(appraiser.id)}
                              >
                                <Avatar
                                  name={getAvatarName(appraiser as User)}
                                  size="32"
                                  round
                                  textSizeRatio={2.28}
                                  className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                                  color="#D1F5FC"
                                  fgColor="#194860"
                                />
                                <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full space-x-1">
                                  {appraiser.firstName}
                                  {appraiser.lastName}
                                </span>
                              </div>
                              {index !==
                                selectedDocument.appraiserDetails.length -
                                  1 && (
                                <span className="text-gray-400">, </span>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </p>
                  </div>
                </div>
              }
            >
              {/* Flexbox Parent */}
              <div className="flex flex-col min-h-full">
                <AnnualAppraisalContent appraisal={selectedDocument} />
              </div>
            </RightDrawer>
          )}
          <div className="border border-gray-200 py-3 px-6 rounded-b-md">
            <FormProvider
              {...{
                ...formMethods,
                handleSubmit,
                setValue,
                getValues,
                reset,
                watch,
              }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                containerClassName="!mt-0"
              />
            </FormProvider>
          </div>
          {!isStaffView && (
            <div className="mt-3 py-3 border-t border-gray-200">
              <Button
                variant="gray"
                onClick={() => navigate(`/dashboard/staff`)}
                className="flex justify-center items-center py-3 pl-3"
                size="noSize"
              >
                <Icon name="back" />
                <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
                  Go back to Staff dashboard
                </span>
              </Button>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
