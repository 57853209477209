import {
  appraisalBase,
  StaffAppraisal,
  updateAppraisers,
} from "api/annualAppraisal";
import { GenericResponse } from "api/types";
import { useGetAdminStaffs } from "api/user";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import { FieldType } from "components/types";
import { handleGenericResponse } from "components/utility";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

interface AnnualAppraisersProps {
  showAppraisers: boolean;
  setShowAppraisers: (show: boolean) => void;
  appraisal: StaffAppraisal;
}
const AnnualAppraisers: React.FC<AnnualAppraisersProps> = ({
  showAppraisers,
  setShowAppraisers,
  appraisal,
}) => {
  const { getValues, clearErrors, setValue } = useFormContext();
  const queryClient = useQueryClient();
  const { data: allAdmins } = useGetAdminStaffs();
  return (
    <Modal
      isOpen={showAppraisers}
      onClose={() => setShowAppraisers(false)}
      modalHeader=""
      className="!bg-white"
      modalBodyClassName="!mt-0 pt-2"
      customModalStyles={{ width: "394px", minWidth: "394px" }}
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center text-gray-900">
        <span className="ml-6 text-gray-900 text-lg leading-6 font-semibold">
          Select Appraisers
        </span>
      </h3>
      <div className="mt-6 pb-6 px-6 space-y-3">
        {allAdmins &&
          allAdmins.length > 0 &&
          allAdmins.map((admin, index) => (
            <Checkbox
              name={`selectedAdmins.${index}`}
              label={`${admin.firstName} ${admin.lastName}`}
              value={admin.id}
              key={admin.id}
              type={FieldType.Checbox}
              defaultValue={
                (appraisal?.appraisers || []).indexOf(admin.id) > -1
              }
            />
          ))}
      </div>
      <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
        <Button variant="white" onClick={() => setShowAppraisers(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const selectedAdmins = (getValues("selectedAdmins") || []).filter(
              (admin: string) => admin
            );
            const response = await updateAppraisers(
              { appraisers: selectedAdmins },
              appraisal.id
            );
            handleGenericResponse(response as unknown as GenericResponse);
            queryClient.invalidateQueries({
              queryKey: [`${appraisalBase}/admin/latest/${appraisal.user.id}`],
            });
            setShowAppraisers(false);
            clearErrors("isAppraiserSelected");
            setValue(
              "isAppraiserSelected",
              selectedAdmins.length > 0 ? "selected" : null
            );
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default AnnualAppraisers;
