import {
  addAppraisalDate,
  appraisalBase,
  updateAppraisalDate,
} from "api/annualAppraisal";
import { User } from "api/types";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import { useQueryClient } from "react-query";
import { Bounce, toast } from "react-toastify";
import { formatDateWithDay, formatTime } from "utils/util";

interface ConfirmAnnualAppraisalDateModalProps {
  showAppraisalConfirmModal: boolean;
  setShowAppraisalConfirmModal: (showAppraisalConfirmModal: boolean) => void;
  user: User;
  appraisalScheduleDate: Date;
  appraisalId: number;
}

const ConfirmAnnualAppraisalDateModal: React.FC<
  ConfirmAnnualAppraisalDateModalProps
> = ({
  showAppraisalConfirmModal,
  setShowAppraisalConfirmModal,
  user,
  appraisalScheduleDate,
  appraisalId,
}: ConfirmAnnualAppraisalDateModalProps) => {
  const queryClient = useQueryClient();
  const confirmAnnualAppraisalTime = async () => {
    const appraisalDate = new Date(appraisalScheduleDate);
    appraisalDate.setHours(appraisalScheduleDate.getHours());
    appraisalDate.setMinutes(appraisalScheduleDate.getMinutes());
    appraisalDate.setSeconds(appraisalScheduleDate.getSeconds());

    let response;

    if (appraisalId) {
      response = await updateAppraisalDate({ appraisalDate }, appraisalId);
    } else {
      response = await addAppraisalDate(
        { appraisalDate: appraisalScheduleDate },
        user.id
      );
    }

    if (response) {
      toast.success(
        `Appraisal ${appraisalId ? "updated" : "created"} successfully`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          icon: <Icon name="check" />,
        }
      );
      queryClient.invalidateQueries({
        queryKey: [`${appraisalBase}/admin/latest/${user.id}`],
      });
      setShowAppraisalConfirmModal(false);
      return;
    }
    toast.error("Error in confirming appraisal date", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="error" />,
    });
  };
  return (
    <Modal
      isOpen={showAppraisalConfirmModal}
      onClose={() => setShowAppraisalConfirmModal(false)}
      modalHeader=""
      className="!bg-primary-50"
      modalBodyClassName="!mt-2"
    >
      <h3 className="text-primary-900 font-bold flex justify-start items-center px-4">
        <Icon name="info" />
        <span className="ml-3">Confirm interview date and time</span>
      </h3>
      <p className="text-primary-800 text-sm leading-5 font-normal px-4 pt-4">
        {`You are about to send an email to ${user.firstName} ${user.lastName} ${user.email} confirming the
        annual appraisal will be on `}
      </p>
      {user?.staffProfile?.interviewDate && (
        <p className="text-primary-800 text-sm leading-5 font-normal px-4 pb-1 space-x-1">
          <span className="text-primary-800 text-sm leading-5 font-bold">
            {`${formatDateWithDay(new Date(appraisalScheduleDate))}
        `}
          </span>
          at
          <span className="text-primary-800 text-sm leading-5 font-bold">
            {`${formatTime(new Date(appraisalScheduleDate))} Melbourne VIC, Australia ${new Date(
              appraisalScheduleDate
            )
              .toLocaleString("en-AU", {
                timeZone: "Australia/Victoria",
                timeZoneName: "shortOffset",
              })
              ?.split(" ")
              ?.pop()}`}
          </span>
        </p>
      )}
      <div className="flex justify-start items-center  rounded-b-md px-4 py-3  rounded-b-md space-x-2">
        <Button
          variant="text"
          size="noSize"
          onClick={() => setShowAppraisalConfirmModal(false)}
          className="text-primary-800"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="text-primary-800"
          onClick={async () => {
            confirmAnnualAppraisalTime();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
export default ConfirmAnnualAppraisalDateModal;
