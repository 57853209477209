import classNames from "classnames";
import React, { useRef } from "react";
import Button from "./Button";
import { Icon } from "./Icon";

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
  additionalHeaderInformation?: React.ReactNode;
  childrenContainerClassName?: string;
  additionalHeaderContainerClassName?: string;
};

const RightDrawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  title,
  children,
  additionalHeaderInformation,
  childrenContainerClassName,
  additionalHeaderContainerClassName,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (drawerRef.current === event.target) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div
          ref={drawerRef}
          className="fixed top-0 right-0 h-full w-full bg-black bg-opacity-40 z-50"
          onClick={handleBackdropClick}
        >
          <div
            className="fixed top-0 right-0 h-full w-1/2 bg-white shadow-lg transform transition-transform translate-x-0 z-50 flex flex-col"
            onClick={(event) => event.stopPropagation()}
          >
            {/* Header */}
            <div
              className={classNames(
                "flex items-center justify-between p-6 border-b border-gray-300",
                additionalHeaderContainerClassName
              )}
            >
              <h2 className="text-lg leading-6 font-semibold text-gray-900">
                {title || "Drawer"}
              </h2>
              {additionalHeaderInformation}
              <Button
                className="absolute right-2 top-2 !rounded-full p-2"
                size="noSize"
                aria-label="Close"
                onClick={onClose}
                variant="gray"
              >
                <Icon name="close" />
              </Button>
            </div>

            {/* Content */}
            <div
              className={classNames(
                "flex-grow overflow-y-auto px-6 py-3",
                childrenContainerClassName
              )}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightDrawer;
