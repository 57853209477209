import { signAppraisal, StaffAppraisal } from "api/annualAppraisal";
import Button from "components/Button";
import Notification from "components/Notification";
import Sign from "components/Sign";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import "jspdf-autotable";
import { FormProvider, useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";
import { formatDateWithTime } from "utils/util";
import AnnualAppraisalContent from "./AnnualAppraisalContent";

export default function StaffSignAnnualAppraisal() {
  const { appraisal } = useLoaderData() as {
    appraisal: StaffAppraisal;
  };
  const navigate = useNavigate();
  const formMethods1 = useForm<FormData>();

  if (!appraisal) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }

  const onSubmit = async (data: FormData) => {
    data.signature = data.staffSign;
    data.signedAt = data.staffSignAt;
    delete data.staffSign;
    delete data.staffSignAt;
    const response = await signAppraisal(data, appraisal.id);
    const isSuccess = await handleResponse(
      response,
      formMethods1.setError,
      () => {},
      `Annual appraisal assigned successfully`
    );
    if (isSuccess) {
      setTimeout(() => navigate("/dashboard"), 3000);
    }
  };

  const appraisalYear = new Date(appraisal.appraisalDate).getFullYear();

  return (
    <>
      <ApplicationMenu navigationItems={[]} />
      <div className="mx-auto max-w-7xl">
        <main className="mx-auto max-w-7xl pt-8">
          <div className="flex items-center justify-between py-3 px-6 border-b border-gray-300">
            <p className="text-3xl leading-9 font-bold text-gray-900">
              {appraisalYear} Annual appraisal acknowledgment
            </p>
          </div>
          <div className="bg-white rounded-md shadow-md mt-6">
            <div className="flex items-center justify-between py-6 px-7 border-b border-gray-300">
              <p className="text-lg leading-6 font-semibold text-gray-900">
                Personal Details
              </p>
            </div>
            <div className="flex items-center justify-start py-6 px-7">
              <div className="flex flex-col justify-start items-start flex-wrap  w-32">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  First name
                </span>
                <span className="text-sm leading-5 font-medium text-gray-900">
                  {appraisal?.user?.firstName || "N/A"}
                </span>
              </div>
              <div className="flex flex-col justify-start items-start flex-wrap ml-6  w-32">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  Last name
                </span>
                <span className="text-sm leading-5 font-medium text-gray-900">
                  {appraisal?.user?.lastName || "N/A"}
                </span>
              </div>
              <div className="flex flex-col justify-start items-start flex-wrap ml-6  w-64">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  Occupation
                </span>
                <span className="text-sm leading-5 font-medium text-gray-900">
                  {appraisal?.user?.staffProfile?.occupation || "N/A"}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-md shadow-md mt-6">
            <div className="flex items-center justify-between py-6 px-7 border-b border-gray-300">
              <p className="text-lg leading-6 font-semibold text-gray-900">
                Annual appraisal details
              </p>
              <div className="flex items-center justify-between space-x-2">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  created date:
                </span>
                <span className="text-sm leading-5 font-medium">
                  {formatDateWithTime(new Date(appraisal.appraisalStartedDate))}
                </span>
              </div>
            </div>
            <div className="mt-3">
              <AnnualAppraisalContent appraisal={appraisal} isStaff />
            </div>
          </div>
          <div className="bg-white rounded-b-md shadow-md mt-6 pt-6">
            <FormProvider {...formMethods1}>
              <form
                id={"signStaffAnnualAppraisal"}
                onSubmit={formMethods1.handleSubmit(onSubmit)}
              >
                <div className="px-6">
                  <Sign
                    name="staffSignAt"
                    label="Signature *"
                    type={FieldType.Sign}
                    defaultValue={appraisal.staffSignAt}
                    defaultSignature={appraisal.staffSign}
                    signatureName="staffSign"
                    successMessageClassName=""
                    signInputClassName="w-105"
                    requiredCondition="This is a required field"
                  />
                  <Notification
                    type="warningExclamation"
                    message={`Note: Please ensure to save below. This will notify your Manager that you have signed and will mark the appraisal review as completed.`}
                    className="!inline-flex w-max !m-0 !mt-8 !mb-4"
                    iconClassName="text-yellow-500"
                  />
                </div>
                <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
                  <Button
                    variant="primary"
                    type="submit"
                    form={`signStaffAnnualAppraisal`}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </main>
      </div>
    </>
  );
}
