import {
  addAppraisalComment,
  appraisalBase,
  AppraisalCommentType,
  AppraisalStatus,
  assignAppraisalToStaff,
  deleteAppraisalComment,
  editAppraisalComment,
  StaffAppraisal,
  startAppraisal,
} from "api/annualAppraisal";
import { User } from "api/types";
import classNames from "classnames";
import Button from "components/Button";
import Comments from "components/Comments";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Sign from "components/Sign";
import Tabs from "components/Tabs";
import { FieldType, FormData } from "components/types";
import Input from "components/UnControlledInput";
import { handleResponse } from "components/utility";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  formatDateWithTime,
  formatTime,
  getIntlTimeZoneOffset,
  isToday,
} from "utils/util";
import AnnualAppraisalHistory from "./AnnualAppraisalHistory";
import AnnualAppraisalReferenceGuide from "./AnnualAppraisalReferenceGuide";
import AnnualAppraisalSchedulePopup from "./AnnualAppraisalSchedulePopup";
import AnnualAppraisers from "./AnnualAppraisers";
import AppraisalTable from "./AppraisalTable";
import ConfirmAnnualAppraisalDateModal from "./ConfirmAnnualAppraisalDateModal";

interface AnnualAppraisalProps {
  user: User;
  admin: User;
  appraisal: StaffAppraisal;
  formMethods1: UseFormReturn<FormData, any, undefined>;
  isSuperAdmin: boolean;
}

export default function AnnualAppraisal({
  user,
  admin,
  appraisal,
  formMethods1,
  isSuperAdmin,
}: AnnualAppraisalProps) {
  const [showAnnualAppraisalDateModal, setShowAnnualAppraisalDateModal] =
    useState(false);
  const [annualAppraisalDate, setAnnualAppraisalDate] = useState<string>();
  const [startAnnualAppraisal, setStartAnnualAppraisal] = useState(false);
  const [showAppraisers, setShowAppraisers] = useState(false);
  const [appraisers, setAppraisers] = useState<number[]>([]);
  const [showReferenceGuide, setShowReferenceGuide] = useState(false);
  const queryClient = useQueryClient();
  const [showAppraisalConfirmModal, setShowAppraisalConfirmModal] =
    useState(false);

  useEffect(() => {
    if (appraisal) {
      if (
        appraisal?.status === AppraisalStatus.InProgress &&
        isToday(appraisal?.appraisalDate)
      ) {
        setStartAnnualAppraisal(true);
      }
      setAnnualAppraisalDate(appraisal?.appraisalDate);
      if (appraisal?.appraisers) {
        setAppraisers([...appraisal.appraisers]);
      }
    }
  }, [appraisal]);

  const queryInvalidateFn = () => {
    return queryClient.invalidateQueries({
      queryKey: [`${appraisalBase}/admin/latest/${user.id}`],
    });
  };

  const addComments = async (content: string, type: AppraisalCommentType) => {
    const response = await addAppraisalComment(
      { comment: content, type: type },
      appraisalId
    );
    return response.staffAppraisalComment;
  };
  const updateComments = async (
    content: string,
    id: number,
    type: AppraisalCommentType
  ) => {
    const response = await editAppraisalComment(
      { comment: content, type: type },
      id
    );
    return response.staffAppraisalComment;
  };

  const appraisalId = appraisal?.id;
  const appraisalScheduleDate = annualAppraisalDate
    ? new Date(annualAppraisalDate)
    : null;
  const isAppraisalConfirmed = appraisal?.status === AppraisalStatus.Confirmed;
  const isAppraisalInProgress =
    appraisal?.status === AppraisalStatus.InProgress;

  const appraisalComments = appraisal ? appraisal?.staffAppraisalComments : [];

  const commentTabs = [
    {
      label: "Goals and objectives action plan",
      content: (
        <div className="my-5">
          <h4 className="text-sm leading-5 font-semibold text-gray-900 mb-5">
            Employee goals and objectives
          </h4>
          <Comments
            admin={admin}
            data={appraisalComments.filter(
              (comment) => comment.type === AppraisalCommentType.EmpGoal
            )}
            queryInvalidateFn={queryInvalidateFn}
            addComments={(newComment: string) =>
              addComments(newComment, AppraisalCommentType.EmpGoal)
            }
            updateComments={async (content: string, id: number) =>
              updateComments(content, id, AppraisalCommentType.EmpGoal)
            }
            deleteComments={async (id: number) =>
              await deleteAppraisalComment(id)
            }
          />
        </div>
      ),
    },
    {
      label: "General comments",
      content: (
        <div className="my-5">
          <h4 className="text-sm leading-5 font-semibold text-gray-900 mb-5">
            General comments
          </h4>
          <Comments
            admin={admin}
            data={appraisalComments.filter(
              (comment) => comment.type === AppraisalCommentType.EmpComment
            )}
            queryInvalidateFn={queryInvalidateFn}
            addComments={(newComment: string) =>
              addComments(newComment, AppraisalCommentType.EmpComment)
            }
            updateComments={async (content: string, id: number) =>
              updateComments(content, id, AppraisalCommentType.EmpComment)
            }
            deleteComments={async (id: number) =>
              await deleteAppraisalComment(id)
            }
          />
        </div>
      ),
    },
    {
      label: "Manager goals and objectives",
      content: (
        <div className="my-5">
          <h4 className="text-sm leading-5 font-semibold text-gray-900 mb-5">
            Manager goals and objectives
          </h4>
          <Comments
            admin={admin}
            data={appraisalComments.filter(
              (comment) => comment.type === AppraisalCommentType.ManagerGoal
            )}
            queryInvalidateFn={queryInvalidateFn}
            addComments={(newComment: string) =>
              addComments(newComment, AppraisalCommentType.ManagerGoal)
            }
            updateComments={async (content: string, id: number) =>
              updateComments(content, id, AppraisalCommentType.ManagerGoal)
            }
            deleteComments={async (id: number) =>
              await deleteAppraisalComment(id)
            }
          />
        </div>
      ),
    },
    {
      label: "Manager general comments",
      content: (
        <div className="my-5">
          <h4 className="text-sm leading-5 font-semibold text-gray-900 mb-5">
            Manager general comments
          </h4>
          <Comments
            admin={admin}
            data={appraisalComments.filter(
              (comment) => comment.type === AppraisalCommentType.ManagerComment
            )}
            queryInvalidateFn={queryInvalidateFn}
            addComments={(newComment: string) =>
              addComments(newComment, AppraisalCommentType.ManagerComment)
            }
            updateComments={async (content: string, id: number) =>
              updateComments(content, id, AppraisalCommentType.ManagerComment)
            }
            deleteComments={async (id: number) =>
              await deleteAppraisalComment(id)
            }
          />
        </div>
      ),
    },
  ];

  const startAnnualAppraisalProcedure = async () => {
    setStartAnnualAppraisal(!startAnnualAppraisal);
    if (isAppraisalConfirmed) {
      const response = await startAppraisal(appraisal?.id);
      const isSuccess = await handleResponse(
        response,
        formMethods1.setError,
        () => {},
        `Annual appraisal started successfully`
      );
      if (isSuccess) {
        setShowAnnualAppraisalDateModal(false);
        queryInvalidateFn();
      }
    }
  };

  const onSubmit = async (data: FormData) => {
    if (!appraisers || appraisers.length === 0) {
      formMethods1.setError("isAppraiserSelected", {
        type: "manual",
        message: "Please select at least one appraiser",
      });
      return;
    }
    data.answers = Object.entries(data.answers).map(([questionId, answer]) => ({
      questionId: Number(questionId),
      answer,
    }));
    data.signature = data.managerSign;
    data.signatureDate = data.managerSignAt;
    delete data.managerSign;
    delete data.managerSignAt;
    delete data.annualAppraisalDate;
    delete data.annualAppraisalTime;
    delete data.selectedAdmins;
    delete data.calendarMonth;
    delete data.calendarYear;
    delete data.isAppraiserSelected;

    const response = await assignAppraisalToStaff(data, appraisalId);
    const isSuccess = await handleResponse(
      response,
      formMethods1.setError,
      () => {},
      `Annual appraisal successfully assigned to staff`
    );
    if (isSuccess) {
      queryInvalidateFn();
      queryClient.invalidateQueries([`${appraisalBase}/admin/list`]);
    }
  };

  return (
    <form
      id={"staffAnnualAppraisal"}
      onSubmit={formMethods1.handleSubmit(onSubmit)}
    >
      <div className={classNames("bg-white shadow rounded-md  mt-5")}>
        <div className={classNames("p-6")}>
          <div
            className={classNames(
              "text-lg leading-6 font-semibold text-gray-900 flex items-center justify-between"
            )}
          >
            <h3>Annual Appraisal/Individual Development & Work Planning</h3>
            <Button
              className="space-x-2"
              onClick={() => setShowAnnualAppraisalDateModal(true)}
              size="sm"
              variant="primary"
              type="button"
              disabled={
                Boolean(appraisalScheduleDate) || appraisal?.assignedToStaff
              }
            >
              <Icon name="solidPlus" />
              <span>Schedule annual appraisal</span>
            </Button>
          </div>
          <div className="mt-5">
            <p className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Annual appraisal scheduled:
            </p>
            {!appraisalScheduleDate || appraisal?.assignedToStaff ? (
              <p className="text-sm leading-5 font-medium text-gray-500">
                No annual appraisal is scheduled yet!
              </p>
            ) : (
              <div className="space-x-1">
                <span
                  className={classNames(
                    "text-sm leading-5 font-semibold text-primary-600 ",
                    annualAppraisalDate !== appraisal?.appraisalDate &&
                      "!text-gray-400"
                  )}
                >
                  {`${new Intl.DateTimeFormat("en-AU", {
                    weekday: "long",
                  }).format(appraisalScheduleDate)}, ${new Intl.DateTimeFormat(
                    "en-AU",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  ).format(appraisalScheduleDate)}`}
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-medium text-gray-900 ",
                    annualAppraisalDate !== appraisal?.appraisalDate &&
                      "!text-gray-400"
                  )}
                >
                  at
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-semibold text-primary-600 ",
                    annualAppraisalDate !== appraisal?.appraisalDate &&
                      "!text-gray-400"
                  )}
                >
                  {formatTime(appraisalScheduleDate)}
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-medium text-primary-900 ",
                    annualAppraisalDate !== appraisal?.appraisalDate &&
                      "!text-gray-400"
                  )}
                >
                  {`Melbourne VIC, Australia ${getIntlTimeZoneOffset(
                    appraisalScheduleDate,
                    "Australia/Victoria"
                  )}`}
                </span>
              </div>
            )}
          </div>
          {appraisalScheduleDate && !appraisal?.assignedToStaff && (
            <div className="flex items-center flex-1 space-x-6 py-6 ">
              <Button
                variant="white"
                type="button"
                className={classNames(
                  "flex items-start justify-center space-x-4",
                  startAnnualAppraisal &&
                    "outline-offset-2 outline-primary-500 outline"
                )}
                disabled={
                  annualAppraisalDate !== appraisal?.appraisalDate ||
                  !isToday(annualAppraisalDate)
                }
                onClick={async () => {
                  startAnnualAppraisalProcedure();
                }}
              >
                <Icon
                  name="downChevron"
                  className={classNames(startAnnualAppraisal && "rotate-180")}
                />
                <span>Start annual appraisal</span>
              </Button>
            </div>
          )}
          {annualAppraisalDate !== appraisal?.appraisalDate &&
            !appraisal?.assignedToStaff && (
              <Notification
                type="warningExclamation"
                message="Note: Click the “Confirm annual appraisal date and time” button below to confirm annual appraisal date and time."
                className="!inline-flex !m-0 w-max"
                iconClassName="text-yellow-500"
              />
            )}
          {startAnnualAppraisal &&
            appraisalScheduleDate &&
            !appraisal?.assignedToStaff && (
              <div>
                <div className="my-6">
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-xl leading-7 font-semibold text-gray-900">
                      Annual appraisal
                    </h3>
                    <div>
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        selected appraisers
                      </span>
                      {appraisers &&
                      appraisers.length > 0 &&
                      appraisal.appraiserDetails &&
                      appraisal.appraiserDetails.length > 0 ? (
                        <div className="flex space-x-2 items-center">
                          <span className="text-sm leading-5 font-medium text-gray-900">
                            {appraisal.appraiserDetails
                              .map(
                                (aprsr) =>
                                  `${aprsr.firstName} ${aprsr.lastName}`
                              )
                              .join(", ")}
                          </span>
                          <Button
                            variant="gray"
                            className="cursor-pointer w-5 h-5 !p-0 !rounded-full"
                            type="button"
                            onClick={() => setShowAppraisers(true)}
                          >
                            <Icon name="pencil" className="text-gray-400" />
                          </Button>
                        </div>
                      ) : (
                        <>
                          <Button
                            size="xs"
                            type="button"
                            onClick={() => setShowAppraisers(true)}
                            className={classNames(
                              formMethods1?.formState?.errors
                                ?.isAppraiserSelected &&
                                "outline outline-offset-1  outline-red-600"
                            )}
                          >
                            Select appraisers
                          </Button>
                          <Input
                            label=""
                            requiredCondition
                            name="isAppraiserSelected"
                            type={FieldType.Input}
                            className="!h-0 !w-0 opacity-0 -z-10 !p-0"
                            containerClassName="!m-0"
                          />
                        </>
                      )}
                      {formMethods1?.formState?.errors?.isAppraiserSelected && (
                        <div role="alert" className="mt-1 text-sm text-red-700">
                          Please select at least one appraiser
                        </div>
                      )}
                    </div>
                    <Button
                      variant="white"
                      type="button"
                      className="space-x-2"
                      onClick={() => setShowReferenceGuide(true)}
                    >
                      <Icon name="info" className="!text-gray-600" />
                      <span>Annual Appraisal Guide</span>
                    </Button>
                  </div>
                </div>
                <div className="shadow rounded-md">
                  <div className="px-5 py-3  border-b border-gray-200 flex items-center">
                    <h5 className="text-sm leading-5 font-semibold text-gray-900 w-1/4">
                      Employee Details
                    </h5>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Employee
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {`${user.title || "-"} ${user.firstName || "-"} ${user.lastName || "-"}`}
                      </span>
                    </div>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Occupation
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {user.staffProfile.occupation}
                      </span>
                    </div>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Commencement date
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {user.staffProfile.employmentStartDate
                          ? formatDateWithTime(
                              new Date(user.staffProfile.employmentStartDate)
                            )
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="px-5 py-3 flex items-center">
                    <h5 className="text-sm leading-5 font-semibold text-gray-900 w-1/4">
                      Management Details
                    </h5>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Reports To Manager
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {`${user.staffProfile?.reportingManager?.title || "N/A"} ${user.staffProfile?.reportingManager?.firstName || "N/A"} ${user.staffProfile?.reportingManager?.lastName || "N/A"}`}
                      </span>
                    </div>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Appraisal start date
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {appraisal?.appraisalStartedDate
                          ? formatDateWithTime(
                              new Date(appraisal?.appraisalStartedDate)
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col items-start w-1/4">
                      <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                        Appraisal completed date
                      </span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        {appraisal?.appraisalCompletionDate
                          ? formatDateWithTime(
                              new Date(appraisal?.appraisalCompletionDate)
                            )
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <AppraisalTable />

                <div className="mt-6">
                  <h3 className="text-xl leading-7 font-semibold mt-6 mb-3">
                    Comments
                  </h3>
                  <div className="mx-auto max-w-7xl  mt-4">
                    <Tabs tabs={commentTabs} />
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl leading-7 font-semibold mt-6 mb-3">
                    Manager/Supervisor signature
                  </h3>
                  <div className="mt-6 p-6 rounded-md shadow-md w-3/4">
                    <Sign
                      name="managerSignAt"
                      label="Manager signature *"
                      type={FieldType.Sign}
                      defaultValue={appraisal?.managerSignAt}
                      defaultSignature={appraisal?.managerSign}
                      signatureName="managerSign"
                      successMessageClassName=""
                      signInputClassName="w-105"
                      requiredCondition="This is a required field"
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
        {isAppraisalInProgress && !appraisal?.assignedToStaff && (
          <div>
            <Notification
              type="warningExclamation"
              message={`After clicking the "Save" button below, tell ${user.firstName} that an email has been sent to their email and they should click the link in the email and sign to complete the annual appraisal review `}
              className="!inline-flex w-max"
              iconClassName="text-yellow-500"
            />
            <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
              <Button
                variant="primary"
                type="submit"
                form={`staffAnnualAppraisal`}
              >
                Save
              </Button>
            </div>
          </div>
        )}
        {appraisalScheduleDate && !isAppraisalInProgress && (
          <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
            <Button
              variant="white"
              onClick={() => setShowAppraisalConfirmModal(true)}
              disabled={annualAppraisalDate === appraisal?.appraisalDate}
            >
              Confirm annual appraisal date and time
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowAnnualAppraisalDateModal(true)}
            >
              Edit
            </Button>
          </div>
        )}
        {showAnnualAppraisalDateModal && (
          <AnnualAppraisalSchedulePopup
            setShowAnnualAppraisalDateModal={setShowAnnualAppraisalDateModal}
            showAnnualAppraisalDateModal={showAnnualAppraisalDateModal}
            formMethods1={formMethods1}
            staffId={user.id}
            appraisalId={appraisal?.id}
            annualAppraisalDate={appraisalScheduleDate}
            setAnnualAppraisalDate={setAnnualAppraisalDate}
          />
        )}
        <AnnualAppraisalReferenceGuide
          showReferenceGuide={showReferenceGuide}
          setShowReferenceGuide={setShowReferenceGuide}
        />
        {appraisalScheduleDate && (
          <ConfirmAnnualAppraisalDateModal
            setShowAppraisalConfirmModal={setShowAppraisalConfirmModal}
            showAppraisalConfirmModal={showAppraisalConfirmModal}
            user={user}
            appraisalScheduleDate={appraisalScheduleDate}
            appraisalId={appraisal?.id}
          />
        )}
        <AnnualAppraisers
          setShowAppraisers={setShowAppraisers}
          showAppraisers={showAppraisers}
          appraisal={appraisal}
        />
      </div>
      <AnnualAppraisalHistory
        staffId={user.id}
        isStaffView
        currentAppraisal={appraisal}
        admin={admin}
        isSuperAdmin={isSuperAdmin}
      />
    </form>
  );
}
