import { User } from "api/types";
import Footer from "components/Footer";
import { Navigate, createBrowserRouter } from "react-router-dom";
import ComponentLib from "./ComponentLib";
import Error from "./Error";
import { DashboardLoader } from "./Loaders/DashboardLoader";
import LoaderWrapper from "./Loaders/LoaderWrapper";
import { MyAccountLoader } from "./Loaders/MyAccountLoader";
import { OnBoardingCompletionLoader } from "./Loaders/OnBoardingCompletionLoader";
import { PersonalCareManagerFormLoader } from "./Loaders/PersonalCareManagerFormLoader";
import { ReportLoader } from "./Loaders/ReportLoader";
import { ReviewNewSubmitedDocumentLoader } from "./Loaders/ReviewNewSubmitedDocumentLoader";
import { ReviewOnboardingLoader } from "./Loaders/ReviewOnboardingLoader";
import { SignAnnualAppraisalStaff } from "./Loaders/SignAnnualAppraisalStaff";
import { StaffOnboardingLoader } from "./Loaders/StaffOnboardingLoader";
import { StartOnBoardingCompletionLoader } from "./Loaders/StartOnBoardingCompletionLoader";
import { ViewStaffLoader } from "./Loaders/ViewStaffLoader";
import Login from "./Login";
import MyAccount from "./MyAccount";
import NotAuthorised from "./NotAuthorised";
import ResetPassword from "./ResetPassword";
import ScheduledAnnualAppraisal from "./ViewStaffDetails/AnnualAppraisal/ScheduledAnnualAppraisal";
import StaffAnnualAppraisal from "./ViewStaffDetails/AnnualAppraisal/StaffAnnualAppraisal";
import StaffSignAnnualAppraisal from "./ViewStaffDetails/AnnualAppraisal/StaffSignAnnualAppraisal";
import ViewStaffDetail from "./ViewStaffDetails/ViewStaffDetail";
import Dashboard from "./dashboard";
import AlliedHealthServices from "./dashboard/AlliedHealthServices";
import Clients from "./dashboard/Clients";
import Contractors from "./dashboard/Contractors";
import ExpiringDocuments from "./dashboard/ExpiringDocuments";
import Help from "./dashboard/Help";
import Home from "./dashboard/Home";
import ReviewNewSubmittedDocuments from "./dashboard/ReviewNewSubmittedDocuments";
import Staff from "./dashboard/staff";
import AllStaff from "./dashboard/staff/AllStaff";
import ArchivedStaff from "./dashboard/staff/ArchivedStaff ";
import { StaffEssentialDocuments } from "./dashboard/staff/StaffEssentialDocuments";
import { StaffOrientation } from "./dashboard/staff/StaffOrientation";
import UpdateExpiredDocument from "./dashboard/staff/UpdateExpiredDocument";
import DeclineInvitation from "./onboarding/DeclineInvitation";
import Onboarding from "./onboarding/Onboarding";
import StartOnboarding from "./onboarding/StartOnboarding";
import StaffOnBoarding from "./onboarding/staff";
import OnBoardingCompletion from "./onboarding/staff/OnBoardingCompletion";
import OnBoardingStaff from "./onboarding/staff/OnBoardingStaff";
import PersonalCareWorkerManagerForm from "./onboarding/staff/PersonalCareWorkerManagerForm";
import ReviewDetail from "./onboarding/staff/reviewDetail";
import Reports from "./reports";
import EmergencyContactDetails from "./reports/EmergencyContactDetails";
import EmploymentChecksRegister from "./reports/EmploymentChecksRegister";
import PoliceCheckReport from "./reports/PoliceCheckReport";

export interface LoaderProps {
  params: any;
  request: any;
  user: User;
}

const WithOptionalFooter: React.FC<{
  showFooter?: boolean;
  children: React.ReactNode;
}> = ({ showFooter = true, children }) => (
  <>
    <main className="flex-1">{children}</main>
    {showFooter && <Footer />}
  </>
);

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <WithOptionalFooter showFooter={false}>
        <Login />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "/dashboard",
    element: (
      <WithOptionalFooter>
        <Dashboard />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
    loader: LoaderWrapper(DashboardLoader),
    children: [
      {
        path: "",
        element: <Home />,
        errorElement: <Error />,
      },
      {
        path: "clients",
        element: <Clients />,
        errorElement: <Error />,
      },
      {
        path: "allied-health-services",
        element: <AlliedHealthServices />,
        errorElement: <Error />,
      },
      {
        path: "contractors",
        element: <Contractors />,
        errorElement: <Error />,
      },
      {
        path: "help",
        element: <Help />,
        errorElement: <Error />,
      },
      {
        path: "expiringDocuments",
        element: <ExpiringDocuments />,
        errorElement: <Error />,
      },
      {
        path: "scheduledAnnualAppraisal",
        element: <ScheduledAnnualAppraisal />,
        errorElement: <Error />,
      },
      {
        path: "review-new-submitted-documents",
        element: <ReviewNewSubmittedDocuments />,
        loader: LoaderWrapper(ReviewNewSubmitedDocumentLoader),
        errorElement: <Error />,
      },
      {
        path: "archivedAccounts",
        element: <ArchivedStaff />,
        errorElement: <Error />,
      },
      {
        path: "staff",
        element: <Staff />,
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <AllStaff />,
            errorElement: <Error />,
          },
          {
            path: "all-staff",
            element: <AllStaff />,
            errorElement: <Error />,
          },
          {
            path: "staff-orientation",
            element: <StaffOrientation />,
            errorElement: <Error />,
          },
          {
            path: "annualAppraisal",
            element: <StaffAnnualAppraisal />,
            errorElement: <Error />,
          },
          {
            path: "essentialDocuments",
            element: <StaffEssentialDocuments />,
            errorElement: <Error />,
          },
        ],
      },
    ],
  },
  {
    path: "onboarding/staff/step/:step",
    element: (
      <WithOptionalFooter>
        <OnBoardingStaff />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(StaffOnboardingLoader),
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/reviewDetails",
    element: (
      <WithOptionalFooter>
        <ReviewDetail />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(ReviewOnboardingLoader),
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/start-onboarding",
    element: (
      <WithOptionalFooter>
        <StartOnboarding />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(StartOnBoardingCompletionLoader),
    errorElement: <Error />,
  },
  {
    path: "onboarding/staff/onBoardingCompletion",
    element: (
      <WithOptionalFooter showFooter={false}>
        <OnBoardingCompletion />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(OnBoardingCompletionLoader),
    errorElement: <Error />,
  },
  {
    path: "/staff/view-profile/:staffId/personal-care-agreement",
    element: (
      <WithOptionalFooter>
        <PersonalCareWorkerManagerForm />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(PersonalCareManagerFormLoader),
    errorElement: <Error />,
  },
  {
    path: "staff/view-profile/:staffId",
    element: (
      <WithOptionalFooter>
        <ViewStaffDetail />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(ViewStaffLoader),
    errorElement: <Error />,
  },
  {
    path: "staff/appraisals/:appraisalId",
    element: (
      <WithOptionalFooter>
        <StaffSignAnnualAppraisal />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(SignAnnualAppraisalStaff),
    errorElement: <Error />,
  },
  {
    path: "not-authorised",
    element: (
      <WithOptionalFooter>
        <NotAuthorised />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "reports",
    element: (
      <WithOptionalFooter>
        <Reports />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(ReportLoader),
    errorElement: <Error />,
    children: [
      {
        path: "employment-checks-register",
        element: <EmploymentChecksRegister />,
        errorElement: <Error />,
      },
      {
        path: "police-check-report",
        element: <PoliceCheckReport />,
        errorElement: <Error />,
      },
      {
        path: "emergency-contacts-details",
        element: <EmergencyContactDetails />,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "staff/my-account",
    element: (
      <WithOptionalFooter>
        <MyAccount />
      </WithOptionalFooter>
    ),
    loader: LoaderWrapper(MyAccountLoader),
    errorElement: <Error />,
  },
  {
    path: "onboarding",
    element: (
      <WithOptionalFooter>
        <Onboarding />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "staff",
        element: <StaffOnBoarding />,
      },
      {
        path: "staff/decline",
        element: <DeclineInvitation />,
      },
    ],
  },
  {
    path: "components",
    element: <ComponentLib />,
    errorElement: <Error />,
  },
  {
    path: "reset-password",
    element: (
      <WithOptionalFooter>
        <ResetPassword />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "existing-set-password",
    element: (
      <WithOptionalFooter>
        <ResetPassword />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "update-expired-document",
    element: (
      <WithOptionalFooter>
        <UpdateExpiredDocument />
      </WithOptionalFooter>
    ),
    errorElement: <Error />,
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />,
    errorElement: <Error />,
  },
]);
