import { AppraisalStatus } from "api/annualAppraisal";
import {
  FailureReasons,
  Session,
  Staff,
  StaffProfile,
  User,
  UserRole,
} from "api/types";
import { useGetStaff } from "api/user";
import classNames from "classnames";
import Badge from "components/Badge";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import { SortOptions } from "components/SortableTable";
import {
  getAvatarName,
  getProfileStatusBadgeProps,
} from "components/StaffDetail";
import StickyTable, { StickyTableColumn } from "components/StickyTable";
import { FieldType, FormData } from "components/types";
import Input from "components/UnControlledInput";
import UserDetailsContent from "components/UserDetailsContent";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { handleViewProfile } from "routes/dashboard/ExpiringDocuments";
import { StaffFilters } from "routes/dashboard/staff/AllStaff";
import { getAppraisalStatus } from "routes/ViewStaffDetails/AnnualAppraisal/AnnualAppraisalHistory";
import { cleanObject, formatDateWithTime, serializeParams } from "utils/util";

export type UnionTypesForStaff =
  | string
  | number
  | UserRole[]
  | Session[]
  | User
  | StaffProfile
  | Notification[]
  | StaffProfile[]
  | FailureReasons[]
  | boolean;

export const isDataCompleted = (value: string, additionalClassName = "") => {
  if (value) {
    return (
      <Badge
        iconName="check"
        message="Completed"
        className={classNames(
          "bg-green-100 text-green-800",
          additionalClassName
        )}
      />
    );
  }
  return (
    <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
  );
};

const columns: StickyTableColumn<Staff>[] = [
  {
    title: "Name",
    group: "Personal Details",
    dataIndex: "name",
    sortable: true,
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.firstName ? (
        <div
          className="flex items-center justify-start w-full cursor-pointer"
          onClick={() => handleViewProfile(row.id)}
        >
          <Avatar
            name={getAvatarName(row)}
            size="32"
            round
            textSizeRatio={2.28}
            className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={row.avatar}
          />
          <div className="flex flex-col items-start ml-4 w-36">
            <span className="underline text-sm leading-5 font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-36">{`${row.firstName} ${row.lastName}`}</span>
          </div>
        </div>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Profile status",
    group: "Personal Details",
    dataIndex: "status",
    Cell: (value: UnionTypesForStaff, row: Staff) => {
      const props = getProfileStatusBadgeProps(row.status);
      props.className = `${props.className} !inline-flex`;
      return <Badge {...props} />;
    },
  },
  { title: "e-mail", group: "Personal Details", dataIndex: "email" },
  {
    title: "suburb",
    group: "Personal Details",
    dataIndex: "staffProfile.residentialAddressSuburb",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.staffProfile?.residentialAddressSuburb
          ? row.staffProfile?.residentialAddressSuburb
          : "N/A"}
      </span>
    ),
  },
  {
    title: "mobile",
    group: "Personal Details",
    dataIndex: "mobile",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.mobile ? row.mobile : "N/A"}
      </span>
    ),
  },
  {
    title: "tfn",
    group: "Personal Details",
    dataIndex: "tfn",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.bankDetails?.tfn ? (
        isDataCompleted(row.staffProfile.bankDetails.tfn)
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Superannuation",
    group: "Personal Details",
    dataIndex: "superannuationFundChoice",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.bankDetails?.superannuationFundChoice ? (
        isDataCompleted(
          row.staffProfile.bankDetails.superannuationFundChoice,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Passport",
    group: "Personal Details",
    dataIndex: "passport",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.passport ? (
        isDataCompleted(row.staffProfile?.passport, "!justify-start w-max")
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "vevo",
    group: "Personal Details",
    dataIndex: "vevoRecord",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.vevoRecord ? (
        isDataCompleted(row.staffProfile?.vevoRecord, "!justify-start w-max")
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "CONTRACT",
    dataIndex: "contract",
    group: "Contract",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.contractSigned ? (
        isDataCompleted(
          row.staffProfile?.contractSigned,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Statutory declaration",
    dataIndex: "statutory",
    group: "Employment declarations",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.signedStatutoryForm ? (
        isDataCompleted(
          row.staffProfile?.signedStatutoryForm,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Employee handbook declaration",
    dataIndex: "readEmployeeHandbook",
    group: "Employment declarations",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.readEmployeeHandbook ? (
        isDataCompleted(
          row.staffProfile?.readEmployeeHandbook ? "yes" : "",
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Signed code of conduct",
    dataIndex: "signatureCodeOfConduct",
    group: "Employment declarations",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.signatureCodeOfConduct ? (
        isDataCompleted(
          row.staffProfile?.signatureCodeOfConduct,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Pre-existing injury/Health declaration",
    dataIndex: "termsDeclarationPreExisting",
    group: "Employment declarations",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.termsDeclarationPreExisting ? (
        isDataCompleted(
          row.staffProfile?.termsDeclarationPreExisting ? "yes" : "",
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  /* {
    title: "Vehicle inspected",
    dataIndex: "vehicleReviewSignature",
    group: "Vehicle",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.vehicleReviewSignature ? (
        isDataCompleted(
          row.staffProfile?.vehicleReviewSignature,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  }, */
  {
    title: "Car registration",
    dataIndex: "vehicleRegistrationExpiry",
    group: "Vehicle",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry ? (
        isDataCompleted(
          row.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Car insurance",
    dataIndex: "vehicleInsurance",
    group: "Vehicle",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.vehicleDetails?.vehicleInsurance ? (
        isDataCompleted(
          row.staffProfile?.vehicleDetails?.vehicleInsurance,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Driver licence",
    dataIndex: "drivingLicenseNumber",
    group: "Vehicle",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.vehicleDetails?.drivingLicenseNumber ? (
        isDataCompleted(
          row.staffProfile?.vehicleDetails?.drivingLicenseNumber,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Immunisation history",
    dataIndex: "immunisationHistory",
    group: "Immunisation",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.immunisationHistory ? (
        isDataCompleted(
          row.staffProfile?.immunisationHistory,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Covid-19 Vaccine",
    dataIndex: "covidVaccineCertificate",
    group: "Immunisation",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.covidVaccineCertificate ? (
        isDataCompleted(
          row.staffProfile?.covidVaccineCertificate,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Police check",
    dataIndex: "policeCheck",
    group: "Police check",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.policeCheck ? (
        isDataCompleted(row.staffProfile?.policeCheck, "!justify-start w-max")
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Aged care certificate/AHPRA",
    dataIndex: "ahpra",
    group: "Education",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.ahpra ? (
        isDataCompleted(row.staffProfile?.ahpra, "!justify-start w-max")
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Aged care certificate/AHPRA date",
    dataIndex: "ahpraDate",
    group: "Education",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.ahpraDate ? (
        isDataCompleted(row.staffProfile?.ahpraDate, "!justify-start w-max")
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Food handling certificate",
    dataIndex: "foodHandlingCertificate",
    group: "Education",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.foodHandlingCertificate ? (
        isDataCompleted(
          row.staffProfile?.foodHandlingCertificate,
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Interview conducted",
    dataIndex: "interviewOutcome",
    group: "Interview",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.interviewOutcome ? (
        isDataCompleted(
          row.staffProfile?.interviewOutcome ? "yes" : "",
          "!justify-start w-max"
        )
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Annual Appraisal",
    dataIndex: "annualAppraisal",
    group: "Annual appraisal",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.appraisalStatus ? (
          <Badge
            {...getAppraisalStatus(
              row.appraisalStatus as AppraisalStatus,
              "!justify-start w-max"
            )}
          />
        ) : (
          "N/A"
        )}
      </span>
    ),
  },
  {
    title: "Annual appraisal date",
    dataIndex: "annualAppraisalDate",
    group: "Annual appraisal",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.appraisalDate
          ? formatDateWithTime(new Date(row.appraisalDate))
          : "N/A"}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "actions",
    group: "Actions",
    isSticky: true,
    columnStyle: "border-l border-gray-300",
  },
];

export default function EmploymentChecksRegister() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [isError, setIsError] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [sortConfig, setSortConfig] = useState<SortOptions<Staff> | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const defaultFilter: Partial<StaffFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();

  const { data, isFetching } = useGetStaff(
    true,
    cleanObject({
      ...filters,
    })
  );
  useEffect(() => {
    if (data) {
      if (Array.isArray(data.staffs) && data.staffs) {
        setStaff(data.staffs);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: "1",
        take: itemsPerPage.toString(),
        order_by: "firstName",
        order: sortConfig.sortOrder,
      });
      setCurrentPage(1);
    }
  }, [sortConfig, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const getBadge = (message: string, key: string) => {
    if (typeof message === "boolean" || key === "archived") {
      return;
    }
    return (
      <Badge
        message={(message || "").split("_").join(" ")}
        key={message}
        iconName="close"
        size={16}
        className="bg-gray-100 mt-3 space-x-2"
        statusClassName="!text-gray-800 !text-sm leading-5 font-medium"
        isIconOnRight
        iconClick={() => {
          setValue("search", "");
          const queryParams = serializeParams(filters);
          queryClient.invalidateQueries([`/api/user/admin/staff`, queryParams]);
          setFilters({ ...filters, [key]: undefined });
          setCurrentPage(1);
        }}
      />
    );
  };

  const getActiveFilterValues = () => {
    const filterValues = Object.keys(
      cleanObject({
        ...filters,
        page: undefined,
        take: undefined,
        itemPerPage: undefined,
      })
    );
    if (filterValues.length > 0) {
      return (
        <div className="flex justify-between items-start mt-3">
          <div className="flex items-center flex-wrap space-x-3 w-3/4">
            <span className="text-gray-900 text-sm mt-3 font-semibold">
              Filtered by:
            </span>
            {filterValues.map((key) => {
              const message = filters[key];
              return Array.isArray(message)
                ? message.map((msg) => getBadge(msg, key))
                : getBadge((message as string) || "", key);
            })}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const updatedData = staff.map((dt) => ({
    ...dt,
    actions: (
      <Button
        variant="text"
        onClick={() => {
          setIsDrawerOpen(true);
          setSelectedStaff(dt);
        }}
      >
        View
      </Button>
    ),
  }));

  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }
  return (
    <FormProvider
      {...{
        ...formMethods,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
      }}
    >
      <div className="">
        <h2 className="text-xl leading-7 font-semibold">
          Employment checks register
        </h2>
        <div className="flex items-center space-x-4 flex-1 mt-6">
          <Input
            name="search"
            label=""
            type={FieldType.Input}
            containerClassName="relative !m-0 w-3/4"
            placeholder="Search firstname, lastname, email, phone number"
            className="pl-10"
            defaultValue={filters.search}
          >
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Icon name="search" />
            </div>
          </Input>
          <Button
            variant="primary"
            size="base"
            className="space-x-2 flex justify-center items-center !px-11"
            onClick={() =>
              setFilters({ ...filters, search: getValues("search") })
            }
          >
            <Icon name="search" className="text-gray-900" />
            <span>Search</span>
          </Button>
        </div>
        <div>{getActiveFilterValues()}</div>
        <div className="mt-6 shadow-md rounded-md border border-gray-200">
          <StickyTable<Staff>
            columns={columns}
            data={updatedData}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
            containerClassName="rounded-t-md"
            hasStickyColumn={true}
          />
          <RightDrawer
            isOpen={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
              setSelectedStaff(null);
            }}
            title="User Details"
            additionalHeaderInformation={
              selectedStaff && (
                <div className="mr-5 space-x-2 flex justify-center items-center">
                  <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                    PROFILE STATUS:
                  </span>
                  <Badge
                    {...getProfileStatusBadgeProps(selectedStaff.status)}
                  />
                </div>
              )
            }
          >
            {selectedStaff && (
              <UserDetailsContent staff={{ ...selectedStaff }} />
            )}
          </RightDrawer>
          <div className=" px-6 pb-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </div>
        </div>

        <div className="mt-3 py-3 border-t border-gray-200">
          <Button
            variant="gray"
            onClick={() => navigate(`/dashboard/staff`)}
            className="flex justify-center items-center py-3 pl-3"
            size="noSize"
          >
            <Icon name="back" />
            <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
              Go back to Staff dashboard
            </span>
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}
