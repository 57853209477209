import {
  AppraisalStatus,
  StaffAppraisal,
  useGetUserAppraisals,
  useGetUserLatestAppraisal,
} from "api/annualAppraisal";
import { User } from "api/types";
import classNames from "classnames";
import Badge from "components/Badge";
import Button from "components/Button";
import { Loading } from "components/Loading";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import SortableTable, { Column, SortOptions } from "components/SortableTable";
import { getAvatarName } from "components/StaffDetail";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { DashboardStaffOutletContext } from "routes/reports";
import {
  formatDateWithTime,
  formatTime,
  getIntlTimeZoneOffset,
} from "utils/util";
import AnnualAppraisalContent from "./AnnualAppraisalContent";
import {
  AnnualAppraisalHistoryFilters,
  getAppraisalStatus,
} from "./AnnualAppraisalHistory";

const fieldToEndpointMapping = {
  status: "status",
  appraisalDate: "date",
  appraisers: "name",
};

export default function StaffAnnualAppraisal() {
  const { user } = useOutletContext<DashboardStaffOutletContext>();
  const componentRef = useRef<HTMLDivElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<StaffAppraisal | null>(null);
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();
  const [documents, setDocuments] = useState<StaffAppraisal[]>([]);

  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const defaultFilter: Partial<AnnualAppraisalHistoryFilters> = {
    take: "10",
    page: "1",
  };
  const [sortConfig, setSortConfig] =
    useState<SortOptions<StaffAppraisal> | null>(null);
  const [filters, setFilters] = useState({ ...defaultFilter });
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };
  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const { data, isLoading, error } = useGetUserAppraisals({
    ...filters,
    filter_status: [AppraisalStatus.InProgress, AppraisalStatus.Completed].join(
      ","
    ),
  });
  const { data: latestAppraisal, error: staffLatestError } =
    useGetUserLatestAppraisal();

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.appraisals) && data.appraisals) {
        setDocuments([...data.appraisals]);
        setTotalItems(data.count || 1);
        return;
      }
      setIsError(true);
    }
  }, [data]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: currentPage.toString(),
        take: itemsPerPage.toString(),
        order_by:
          fieldToEndpointMapping[
            sortConfig.sortField as keyof typeof fieldToEndpointMapping
          ],
        order: sortConfig.sortOrder,
      });
    }
  }, [sortConfig, currentPage, itemsPerPage]);

  const columns: Column<StaffAppraisal>[] = [
    {
      header: "Appraised by",
      accessor: "appraisers",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) =>
        row.appraisers && row.appraisers.length > 0 ? (
          <div className="flex items-center justify-start space-x-1 cursor-pointer w-full pr-1">
            {row.appraisers.map((id, index) => (
              <React.Fragment key={id}>
                <div
                  className="flex items-center space-x-1 cursor-pointer overflow-hidden"
                  style={{ maxWidth: "calc(100% - 1rem)" }} // Adjust max-width to fit your layout
                >
                  <Avatar
                    name={getAvatarName(row.createdBy as User)}
                    size="32"
                    round
                    textSizeRatio={2.28}
                    className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                    color="#D1F5FC"
                    fgColor="#194860"
                  />
                  <span className="truncate text-sm leading-5 font-semibold text-gray-900">
                    {row.createdBy.firstName} {row.createdBy.lastName}
                  </span>
                </div>
                {index !== row.appraisers.length - 1 && (
                  <span className="text-gray-400">, </span>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <></>
        ),
      columnWidth: "35%",
    },
    {
      header: "Review date and time",
      accessor: "appraisalDate",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {formatDateWithTime(new Date(row.appraisalStartedDate as string))}
        </span>
      ),
      columnWidth: "30%",
    },
    {
      header: "Status",
      accessor: "status",
      sortable: true,
      Cell: (value: any, row: StaffAppraisal) => (
        <div className="text-gray-500 inline-flex">
          <Badge {...getAppraisalStatus(value as AppraisalStatus)} />
        </div>
      ),
      columnWidth: "25%",
    },
    {
      header: "",
      accessor: "id",
      Cell: (value: any, row: StaffAppraisal) => (
        <Button
          variant="text"
          className="w-3/4 ml-2"
          onClick={() => {
            setIsDrawerOpen(true);
            setSelectedDocument(row);
          }}
        >
          View
        </Button>
      ),
      columnWidth: "10%",
    },
  ];
  if (isLoading || !data) {
    return <Loading />;
  }
  if (isError || error || staffLatestError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }
  const appraisal = latestAppraisal ? latestAppraisal.appraisal : null;
  const appraisalScheduleDate = appraisal?.appraisalDate
    ? new Date(appraisal?.appraisalDate)
    : null;
  return (
    <div className="py-1">
      <header className="mx-auto">
        <h1 className="text-xl leading-7 font-semibold">Annual Appraisal</h1>
      </header>
      <main className="mt-5">
        <div className="mx-auto p-6 bg-white rounded-md shadow-sm">
          {appraisalScheduleDate &&
            appraisal?.status === AppraisalStatus.Confirmed && (
              <div className="space-x-1 bg-primary-50 p-3 rounded-md">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase">
                  Next appraisal date and time:
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-semibold text-primary-600 "
                  )}
                >
                  {`${new Intl.DateTimeFormat("en-AU", {
                    weekday: "long",
                  }).format(appraisalScheduleDate)}, ${new Intl.DateTimeFormat(
                    "en-AU",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  ).format(appraisalScheduleDate)}`}
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-medium text-gray-900 "
                  )}
                >
                  at
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-semibold text-primary-600 "
                  )}
                >
                  {formatTime(appraisalScheduleDate)}
                </span>
                <span
                  className={classNames(
                    "text-sm leading-5 font-medium text-primary-900 "
                  )}
                >
                  {`Melbourne VIC, Australia ${getIntlTimeZoneOffset(
                    appraisalScheduleDate,
                    "Australia/Victoria"
                  )}`}
                </span>
              </div>
            )}
          {documents.length > 0 && (
            <div className="mt-5">
              <div ref={componentRef}>
                <SortableTable
                  columns={columns}
                  data={[...documents]}
                  setSortConfig={setSortConfig}
                  sortConfig={sortConfig}
                  containerClassName="!shadow-none rounded-t-md border-b-0"
                />
              </div>
              {selectedDocument && (
                <RightDrawer
                  isOpen={isDrawerOpen}
                  onClose={() => {
                    setIsDrawerOpen(false);
                    setSelectedDocument(null);
                  }}
                  title={`Appraisal detaills`}
                  childrenContainerClassName="!p-0"
                  additionalHeaderContainerClassName="flex-col !items-start"
                  additionalHeaderInformation={
                    <div className="flex flex-col items-start w-full">
                      <div className="flex items-center justify-between  w-1/2 mt-5">
                        <p>
                          <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                            created date:
                          </span>
                          <span className="text-sm leading-5 font-medium">
                            {selectedDocument.appraisalStartedDate
                              ? formatDateWithTime(
                                  new Date(
                                    selectedDocument.appraisalStartedDate
                                  )
                                )
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                            completed date:
                          </span>
                          <span className="text-sm leading-5 font-medium">
                            {selectedDocument.appraisalCompletionDate
                              ? formatDateWithTime(
                                  new Date(
                                    selectedDocument.appraisalCompletionDate
                                  )
                                )
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center justify-between w-full mt-2">
                        <p className="flex items-center justify-between space-x-2">
                          <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                            Appraised by:
                          </span>
                          <div className="text-sm leading-5 font-medium flex items-center space-x-1">
                            {selectedDocument.appraisers.map((id, index) => (
                              <React.Fragment key={id}>
                                <div className="flex items-center justify-start space-x-1 cursor-pointer">
                                  <Avatar
                                    name={getAvatarName(
                                      selectedDocument.createdBy as User
                                    )}
                                    size="32"
                                    round
                                    textSizeRatio={2.28}
                                    className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                                    color="#D1F5FC"
                                    fgColor="#194860"
                                  />
                                  <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full space-x-1">
                                    {selectedDocument.createdBy.firstName}
                                    {selectedDocument.createdBy.lastName}
                                  </span>
                                </div>
                                {index !==
                                  selectedDocument.appraisers.length - 1 && (
                                  <span className="text-gray-400">, </span>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        </p>
                      </div>
                    </div>
                  }
                >
                  {/* Flexbox Parent */}
                  <div className="flex flex-col min-h-full">
                    <AnnualAppraisalContent
                      appraisal={selectedDocument}
                      user={user}
                      isStaff
                    />
                  </div>
                </RightDrawer>
              )}

              <div className="border border-gray-200 py-3 px-6 rounded-b-md">
                <FormProvider
                  {...{
                    ...formMethods,
                    handleSubmit,
                    setValue,
                    getValues,
                    reset,
                    watch,
                  }}
                >
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onNextPage={handleNextPage}
                    onPreviousPage={handlePreviousPage}
                    containerClassName="!mt-0"
                  />
                </FormProvider>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
