import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabProps } from "./Tab";

interface TabsProps {
  tabs: TabProps[];
  defaultActive?: number;
  tabContentContainerClassName?: string;
  syncWithUrl?: boolean; // New prop to control URL syncing
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultActive = 0,
  tabContentContainerClassName,
  syncWithUrl = false, // Default: false (do not sync with URL)
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromUrl = Number(searchParams.get("tab"));

  const [activeTab, setActiveTab] = useState(
    syncWithUrl &&
      !isNaN(tabFromUrl) &&
      tabFromUrl >= 0 &&
      tabFromUrl < tabs.length
      ? tabFromUrl
      : defaultActive
  );

  useEffect(() => {
    if (
      syncWithUrl &&
      !isNaN(tabFromUrl) &&
      tabFromUrl >= 0 &&
      tabFromUrl < tabs.length
    ) {
      setActiveTab(tabFromUrl);
    }
  }, [tabFromUrl, tabs.length, syncWithUrl]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (syncWithUrl) {
      setSearchParams({ tab: index.toString() }); // Update URL only if syncWithUrl is true
    }
  };

  return (
    <div className="w-full">
      <div className="flex">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            {...tab}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
            tabClassName={classNames(
              index === 0 && "rounded-l-md",
              index === tabs.length - 1 && "rounded-r-md"
            )}
          />
        ))}
      </div>
      <div className={classNames(tabContentContainerClassName)}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
