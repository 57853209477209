import { Icon, IconName } from "components/Icon";
import { Link, useMatch } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export default function Sidebar({ isAdmin }: { isAdmin: boolean }) {
  const match = useMatch("/dashboard/staff/:childPath/*");
  const childPath = match?.params.childPath ?? "/";
  type NavigationItem = {
    name: string;
    href: string;
    icon: string;
    current: boolean;
    isOpenInNewTab?: boolean;
  };

  const adminNavigation: NavigationItem[] = [
    {
      name: "All staff",
      href: "all-staff",
      icon: "allStaff",
      current: childPath === "all-staff" || childPath === "/",
    },
    /* {
      name: "Orientation",
      href: "#",
      icon: "orientation",
      current: false,
    },
    {
      name: "Annual leave",
      href: "#",
      icon: "annualLeave",
      current: false,
    }, */
    {
      name: "Expiring documents",
      href: "/dashboard/expiringDocuments",
      icon: "greyDocument",
      current: childPath === "expiringDocuments",
    },
    {
      name: "Review new submitted documents",
      href: "/dashboard/review-new-submitted-documents",
      icon: "documentExpired",
      current: childPath === "review-new-submitted-documents",
    },
    {
      name: "Archived accounts",
      href: "/dashboard/archivedAccounts",
      icon: "solidArchive",
      current: childPath === "archivedAccounts",
      isOpenInNewTab: true,
    },
    {
      name: "Scheduled annual appraisal",
      href: "/dashboard/scheduledAnnualAppraisal",
      icon: "solidCalendar",
      current: childPath === "scheduledAnnualAppraisal",
      isOpenInNewTab: true,
    },
    /* {
      name: "Add new staff",
      href: "add-staff",
      icon: "addNewStaff",
      current: childPath === "add-staff",
    },
    {
      name: "Annual leave",
      href: "#",
      icon: "annualLeave",
      current: false,
    },
    {
      name: "Create interview",
      href: "#",
      icon: "createInterview",
      current: false,
    },
    {
      name: "Training resources",
      href: "#",
      icon: "trainingResource",
      current: false,
    },
    { name: "Staff survey", href: "#", icon: "staffSurvey", current: false },
    {
      name: "Contact",
      href: "#",
      icon: "contact",
      current: false,
    }, */
  ];
  const staffNavigation: NavigationItem[] = [
    {
      name: "Orientation",
      href: "/dashboard/staff/staff-orientation",
      icon: "orientation",
      current: childPath === "staff-orientation",
    },
    /* {
      name: "Annual leave",
      href: "#",
      icon: "annualLeave",
      current: false,
    }, */
    {
      name: "Annual appraisal",
      href: "/dashboard/staff/annualAppraisal",
      icon: "trendingUp",
      current: childPath === "annualAppraisal",
    },
    {
      name: "Essential documents",
      href: "/dashboard/staff/essentialDocuments",
      icon: "greyDocument",
      current: childPath === "essentialDocuments",
    },
    /* {
      name: "Create Interview",
      href: "#",
      icon: "createInterview",
      current: false,
    },
    {
      name: "Training Resources",
      href: "#",
      icon: "trainingResource",
      current: false,
    },
    { name: "Staff Survey", href: "#", icon: "staffSurvey", current: false },
    {
      name: "Contact",
      href: "#",
      icon: "contact",
      current: false,
    }, */
  ];

  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul className="space-y-1">
        {(isAdmin ? adminNavigation : staffNavigation).map((item) => (
          <li key={item.name} className="!m-0">
            <Link
              to={item.href}
              className={classNames(
                item.current
                  ? "bg-primary-100 text-gray-900 hover:bg-primary-200"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-200",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              )}
              target={item.isOpenInNewTab ? "_blank" : undefined}
              rel={item.isOpenInNewTab ? "noopener noreferrer" : undefined}
            >
              {item.icon && (
                <Icon
                  name={item.icon as IconName}
                  className={classNames(
                    item.current
                      ? "text-gray-900"
                      : "text-gray-600 group-hover:text-gray-900 hover:text-gray-900",
                    "h-5 w-5 shrink-0"
                  )}
                />
              )}
              {/* <item.icon
                className={classNames(
                  item.current
                    ? "text-gray-800"
                    : "text-gray-600 group-hover:text-gray-800",
                  "h-6 w-6 shrink-0"
                )}
                aria-hidden="true"
              /> */}
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
