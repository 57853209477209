import { AppraisalStatus } from "api/annualAppraisal";
import { Staff } from "api/types";
import classNames from "classnames";
import React from "react";
import Avatar from "react-avatar";
import { handleViewProfile } from "routes/dashboard/ExpiringDocuments";
import { isDataCompleted } from "routes/reports/EmploymentChecksRegister";
import { getAppraisalStatus } from "routes/ViewStaffDetails/AnnualAppraisal/AnnualAppraisalHistory";
import { formatDateWithTime } from "utils/util";
import Badge from "./Badge";
import { getAvatarName } from "./StaffDetail";

export interface Section {
  title: string;
  details: { label: string; value: string | React.ReactNode }[];
}

interface UserDetailsContentProps {
  staff: Staff;
  otherSections?: Section[];
  containerClassName?: string;
}

const UserDetailsContent: React.FC<UserDetailsContentProps> = ({
  staff,
  otherSections,
  containerClassName,
}) => {
  const defaultSections: Section[] = [
    {
      title: "",
      details: [
        {
          label: "TFN",
          value: isDataCompleted(staff.staffProfile.bankDetails?.tfn),
        },
        {
          label: "Superannuation",
          value: isDataCompleted(
            staff.staffProfile.bankDetails?.superannuationFund
          ),
        },
        {
          label: "Passport",
          value: isDataCompleted(staff.staffProfile.passport),
        },
        {
          label: "VEVO",
          value: isDataCompleted(staff.staffProfile.vevoRecord),
        },
      ],
    },
    {
      title: "Contract",
      details: [
        {
          label: "Contract Signed",
          value: isDataCompleted(staff.staffProfile.contractSigned),
        },
      ],
    },
    {
      title: "Employment declarations",
      details: [
        {
          label: "Statutory Declaration",
          value: isDataCompleted(staff.staffProfile.signedStatutoryForm),
        },
        {
          label: "Employee Handbook Declaration",
          value: isDataCompleted(
            staff.staffProfile.readEmployeeHandbook ? "yes" : ""
          ),
        },
        {
          label: "Signed Code of Conduct",
          value: isDataCompleted(staff.staffProfile.signatureCodeOfConduct),
        },
        {
          label: "Pre-Existing Injury/Health Declaration",
          value: isDataCompleted(
            staff.staffProfile.termsDeclarationPreExisting ? "yes" : ""
          ),
        },
      ],
    },
    {
      title: "Vehicle",
      details: [
        /* {
          label: "Vehicle inspected",
          value: isDataCompleted(staff.staffProfile?.vehicleReviewSignature),
        }, */
        {
          label: "Car registration",
          value: isDataCompleted(
            staff.staffProfile.vehicleDetails?.vehicleRegistrationExpiry
          ),
        },
        {
          label: "Car insurance",
          value: isDataCompleted(
            staff.staffProfile.vehicleDetails?.vehicleInsurance
          ),
        },
        {
          label: "Driver licence",
          value: isDataCompleted(
            staff.staffProfile.vehicleDetails?.drivingLicenseNumber
          ),
        },
      ],
    },
    {
      title: "Immunisation",
      details: [
        {
          label: "Immunisation history",
          value: isDataCompleted(staff.staffProfile?.immunisationHistory),
        },
        {
          label: "Covid-19 Vaccine",
          value: isDataCompleted(staff.staffProfile?.covidVaccineCertificate),
        },
      ],
    },
    {
      title: "Police check",
      details: [
        {
          label: "Police check",
          value: isDataCompleted(staff.staffProfile?.policeCheck),
        },
      ],
    },
    {
      title: "Education",
      details: [
        {
          label: "Aged care certificate/AHPRA",
          value: isDataCompleted(staff.staffProfile?.ahpra),
        },
        {
          label: "Aged care certificate/AHPRA date",
          value: isDataCompleted(staff.staffProfile?.ahpraDate),
        },
        {
          label: "Food handling certificate",
          value: isDataCompleted(staff.staffProfile?.foodHandlingCertificate),
        },
      ],
    },
    {
      title: "Interview",
      details: [
        {
          label: "Interview conducted",
          value: isDataCompleted(staff.staffProfile?.ahpra),
        },
      ],
    },
    {
      title: "Annual appraisal",
      details: [
        {
          label: "Annual Appraisal",
          value: staff.appraisalStatus ? (
            <Badge
              {...getAppraisalStatus(
                staff.appraisalStatus as AppraisalStatus,
                "!justify-start w-max"
              )}
            />
          ) : (
            "N/A"
          ),
        },
        {
          label: "Annual appraisal date",
          value: staff.appraisalDate
            ? formatDateWithTime(new Date(staff.appraisalDate))
            : "N/A",
        },
      ],
    },
  ];

  return (
    <div>
      <div className={classNames("shadow rounded-t-lg", containerClassName)}>
        <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg">
          Personal details
        </h3>
        <div className="px-6 py-2.5 bg-gray-50 border-b border-gray-200">
          <div className="flex space-x-12 py-1 ">
            <div
              className="flex items-center justify-start cursor-pointer"
              onClick={() => handleViewProfile(staff.id)}
            >
              <Avatar
                name={getAvatarName(staff)}
                size="32"
                round
                textSizeRatio={2.28}
                className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                color="#D1F5FC"
                fgColor="#194860"
                src={staff.avatar}
              />
              <div className="flex flex-col items-start ml-4 ">
                <span className="underline text-base leading-6 font-semibold text-gray-900">{`${staff.firstName} ${staff.lastName}`}</span>
              </div>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Email
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.email || "N/A"}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Phone
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.mobile || "N/A"}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Suburb
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff?.staffProfile?.residentialAddressSuburb || "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {(otherSections || defaultSections).map((section, index) => (
        <div key={index} className={classNames("mb-6")}>
          {section.title && (
            <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg shadow">
              {section.title}
            </h3>
          )}
          <div className="px-6 bg-white shadow rounded-b-lg">
            {section.details.map((detail, idx) => (
              <div
                key={idx}
                className={classNames(
                  "flex justify-between items-center py-3 ",
                  section.details.length - 1 !== idx &&
                    "border-b border-gray-200"
                )}
              >
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  {detail.label}
                </span>
                <span>{detail.value}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserDetailsContent;
