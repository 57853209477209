import { getAppraisalDetails } from "api/annualAppraisal";
import { redirect } from "react-router-dom";
import { user as userAPI } from "../../api";

export async function SignAnnualAppraisalStaff({ params, request }: any) {
  const { appraisalId } = params;
  const url = new URL(request.url);
  if (!appraisalId) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const authData = window.localStorage.getItem("eicare_auth");
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }

  const [appraisalDetail, userDetail] = await Promise.all([
    getAppraisalDetails(appraisalId),
    userAPI.getUser(),
  ]);

  if (!(appraisalDetail && appraisalDetail.appraisal)) {
    return redirect(`/not-authorised`);
  }

  if (userDetail.id !== appraisalDetail.appraisal.user.id) {
    return redirect(`/not-authorised`);
  }

  const { appraisal } = appraisalDetail;

  return {
    appraisal: { ...appraisal },
  };
}
