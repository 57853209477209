import { User } from "api/types";
import Button from "components/Button";
import { Icon } from "components/Icon";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import CreateStaffAccount from "./CreateStaffAccount";
import InviteStaff from "./InviteStaff";
import Sidebar from "./Sidebar";

export interface DashboardStaffOutletContext {
  user: User;
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

export default function Staff() {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false);
  const { user, isAdmin, isSuperAdmin } =
    useOutletContext<DashboardStaffOutletContext>();

  return (
    <div className="py-1">
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-200 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl">{isAdmin ? "Staff" : "Resources"}</h1>
          {isAdmin && (
            <div className="flex justify-center items-center text-sm">
              <Button
                onClick={() => setIsCreateAccountOpen(true)}
                variant="primary"
                className="flex justify-center items-center mx-2 "
                size="large"
              >
                <Icon name="plus" className="mr-2" /> Create staff account
              </Button>
              <Button
                onClick={() => setIsOpen(true)}
                variant="white"
                className="flex justify-center items-center"
                size="large"
              >
                <Icon name="inviteUser" />
                <span className="ml-2"> Invite</span>
              </Button>
            </div>
          )}
        </div>
      </header>
      <main className="mb-11">
        <div className="flex mx-auto max-w-7xl pt-8">
          <div className="md:w-1/4 pr-8 hidden sm:block">
            <Sidebar isAdmin={isAdmin} />
          </div>
          <div className="flex flex-col md:w-3/4 pl-2">
            <Outlet context={{ user, isAdmin }} />
          </div>
        </div>
        <InviteStaff
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isSuperAdmin={isSuperAdmin}
        />
        <CreateStaffAccount
          isOpen={isCreateAccountOpen}
          setIsOpen={setIsCreateAccountOpen}
        />
      </main>
    </div>
  );
}
