import {
  AppraisalCommentType,
  StaffAppraisal,
  StaffAppraisalQuestion,
  useGetAppraisalQuestions,
} from "api/annualAppraisal";
import { User } from "api/types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { formatDateWithTime } from "utils/util";
import { expectationsMap } from "./AppraisalTable";

export interface Section {
  title: string;
  details: { label: string; value: string | React.ReactNode }[];
}

interface AnnualAppraisalContentProps {
  appraisal: StaffAppraisal;
  containerClassName?: string;
  user?: User;
  isStaff?: Boolean;
}

const AnnualAppraisalContent: React.FC<AnnualAppraisalContentProps> = ({
  appraisal,
  containerClassName,
  user,
  isStaff = false,
}) => {
  const [appraisalQuestions, setAppraisalQuestions] = useState<
    StaffAppraisalQuestion[]
  >([]);

  const { data } = useGetAppraisalQuestions();
  useEffect(() => {
    if (data) {
      setAppraisalQuestions(data.appraisalQuestions);
    }
  }, [data]);
  const defaultSections: Section[] = [
    {
      title: "Appraisal criteria",
      details: appraisalQuestions.map((row) => ({
        label: row.name,
        value: (appraisal.answers || []).find(
          (ans) => ans.questionId === row.id
        )
          ? expectationsMap[
              appraisal.answers.find((ans) => ans.questionId === row.id)
                ?.answer as 1 | 2 | 3
            ]
          : "N/A",
      })),
    },
    {
      title: "Manager/Supervisor",
      details: [
        {
          label: "Manager/Supervisor",
          value: appraisal?.user?.staffProfile?.reportingManager
            ? `${appraisal.user.staffProfile.reportingManager.firstName} ${appraisal.user.staffProfile.reportingManager.lastName}`
            : user?.staffProfile?.reportingManager
              ? `${user.staffProfile.reportingManager.firstName} ${user.staffProfile.reportingManager.lastName}`
              : "N/A",
        },
        {
          label: "Manager/Supervisor signature",
          value: appraisal.managerSign || "N/A",
        },
        {
          label: "Manager/Supervisor sign date and time",
          value: appraisal.managerSignAt
            ? formatDateWithTime(new Date(appraisal.managerSignAt))
            : "N/A",
        },
      ],
    },
    {
      title: "Employee",
      details: [
        {
          label: "Employee",
          value: appraisal?.user?.firstName
            ? `${appraisal.user.firstName} ${appraisal.user.lastName}`
            : user
              ? `${user.firstName} ${user.lastName}`
              : "N/A",
        },
        {
          label: "Occupation",
          value:
            appraisal?.user?.staffProfile?.occupation ||
            user?.staffProfile?.occupation ||
            "N/A",
        },
        {
          label: "Employee signature",
          value: appraisal.staffSign || "N/A",
        },
        {
          label: "Employee sign date and time",
          value: appraisal.staffSignAt
            ? formatDateWithTime(new Date(appraisal.staffSignAt))
            : "N/A",
        },
      ],
    },
    {
      title: "Employee goals and objectives",
      details: (appraisal.staffAppraisalComments || [])
        .filter((comment) => comment.type === AppraisalCommentType.EmpGoal)
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
    {
      title: "Employee general comments",
      details: (appraisal.staffAppraisalComments || [])
        .filter((comment) => comment.type === AppraisalCommentType.EmpComment)
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
    {
      title: "Manager goals and objectives",
      details: (appraisal.staffAppraisalComments || [])
        .filter((comment) => comment.type === AppraisalCommentType.ManagerGoal)
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
    {
      title: "Manager general comments",
      details: (appraisal.staffAppraisalComments || [])
        .filter(
          (comment) => comment.type === AppraisalCommentType.ManagerComment
        )
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
  ];

  const staffSections: Section[] = [
    {
      title: "Appraisal criteria",
      details: appraisalQuestions.map((row) => ({
        label: row.name,
        value: (appraisal.answers || []).find(
          (ans) => ans.questionId === row.id
        )
          ? expectationsMap[
              appraisal.answers.find((ans) => ans.questionId === row.id)
                ?.answer as 1 | 2 | 3
            ]
          : "N/A",
      })),
    },
    {
      title: "Manager/Supervisor",
      details: [
        {
          label: "Manager/Supervisor",
          value: appraisal?.user?.staffProfile?.reportingManager
            ? `${appraisal.user.staffProfile.reportingManager.firstName} ${appraisal.user.staffProfile.reportingManager.lastName}`
            : user?.staffProfile?.reportingManager
              ? `${user.staffProfile.reportingManager.firstName} ${user.staffProfile.reportingManager.lastName}`
              : "N/A",
        },
        {
          label: "Manager/Supervisor signature",
          value: appraisal.managerSign || "N/A",
        },
        {
          label: "Manager/Supervisor sign date and time",
          value: appraisal.managerSignAt
            ? formatDateWithTime(new Date(appraisal.managerSignAt))
            : "N/A",
        },
      ],
    },
    {
      title: "Employee",
      details: [
        {
          label: "Employee",
          value: appraisal?.user?.firstName
            ? `${appraisal.user.firstName} ${appraisal.user.lastName}`
            : user
              ? `${user.firstName} ${user.lastName}`
              : "N/A",
        },
        {
          label: "Occupation",
          value:
            appraisal?.user?.staffProfile?.occupation ||
            user?.staffProfile?.occupation ||
            "N/A",
        },
        {
          label: "Employee signature",
          value: appraisal.staffSign || "N/A",
        },
        {
          label: "Employee sign date and time",
          value: appraisal.staffSignAt
            ? formatDateWithTime(new Date(appraisal.staffSignAt))
            : "N/A",
        },
      ],
    },
    {
      title: "Employee goals and objectives",
      details: (appraisal.staffAppraisalComments || [])
        .filter((comment) => comment.type === AppraisalCommentType.EmpGoal)
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
    {
      title: "Employee general comments",
      details: (appraisal.staffAppraisalComments || [])
        .filter((comment) => comment.type === AppraisalCommentType.EmpComment)
        .map((comment) => ({
          label: "",
          value: comment.comment,
        })),
    },
  ];

  return (
    <div>
      {(isStaff ? staffSections : defaultSections).map((section, index) => (
        <div key={index} className={classNames("py-4 px-6")}>
          {section.title && (
            <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg shadow">
              {section.title}
            </h3>
          )}
          <div className="px-6 bg-white shadow rounded-b-lg">
            {section.details.map((detail, idx) => (
              <div
                key={idx}
                className={classNames(
                  "flex justify-between items-center py-3 ",
                  section.details.length - 1 !== idx &&
                    "border-b border-gray-200"
                )}
              >
                {detail.label && (
                  <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                    {detail.label}
                  </span>
                )}
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {detail.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnnualAppraisalContent;
