import Enable2FA from "components/Enable2FA";
import ExpiredDocument from "components/ExpiredDocument";
import { UserProvider } from "context/UserContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NewEmployedStaffDocument from "routes/currentEmployeeOnboarding/NewEmployedStaffDocument";
import { router } from "routes/root";
import { isFeatureEnabled } from "utils/util";
import "./App.css";

const contextClass = {
  success: "bg-green-50 text-green-900",
  error: "bg-red-100 text-red-900 text-sm",
  info: "bg-primary-50 text-primary-900",
  warning: "bg-yellow-50 text-yellow-900",
  default: "bg-indigo-600",
};

function App() {
  const queryClient = new QueryClient();
  return (
    <div>
      <div className="flex flex-col min-h-screen">
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <ExpiredDocument />
            <NewEmployedStaffDocument />
            {isFeatureEnabled() && <Enable2FA />}

            <RouterProvider router={router} />
            <ToastContainer
              toastClassName={(context) =>
                contextClass[context?.type || "default"] +
                " relative flex p-1 min-h-16 font-semibold rounded-md justify-between overflow-hidden cursor-pointer"
              }
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </UserProvider>
        </QueryClientProvider>
      </div>
    </div>
  );
}

export default App;
