import { TwoFASetup } from "api/types";
import { UserContextData, useUser } from "context/UserContext";
import { useEffect, useState } from "react";
import { user as userApi } from "../api";
import Dialogue from "./Dialogue";
import { Icon } from "./Icon";
import Input from "./Input";

export interface TwoFASetupProps {
  twoFaSecret: string;
  dataURL: string;
  otp_url: string;
}

export default function Setup2FA() {
  const { user, refreshUser, loading } = useUser() as UserContextData;
  const [openDialogue, setOpenDialogue] = useState(false);
  const [sixDigitCode, setSixDigitCode] = useState("");
  const [error, setError] = useState<{
    sixDigitCode: string | null;
  }>({
    sixDigitCode: null,
  });
  const [twoFaSetup, setTwoFaSetup] = useState<TwoFASetupProps | null>(null);

  useEffect(() => {
    const fetchSetupDetails = async () => {
      try {
        if (user?.twoFASetup === TwoFASetup.SETTING_UP) {
          const data = await userApi.TwoFASetup();
          setOpenDialogue(true);
          setTwoFaSetup(data);
        }
      } catch (error) {
        console.error("Error setting up 2FA:", error);
      }
    };
    fetchSetupDetails();
  }, [user?.twoFASetup]);

  const handle2FALogin = async () => {
    try {
      await userApi.TwoFALogin(sixDigitCode);
      setOpenDialogue(false);
      refreshUser();
    } catch (err) {
      console.error("Login failed", err);
      setError({ ...error, sixDigitCode: "Code is invalid" });
    }
  };

  if (
    loading ||
    !user?.id ||
    (user && user.twoFASetup === TwoFASetup.SETUP_COMPLETE) ||
    !user.twoFARequired
  ) {
    return null; // or a loading spinner
  }

  return (
    <Dialogue
      key={"enable2FA"}
      title="Enable Google Authenticator for your EICARE staff account"
      confirmButtonLabel="Activate"
      panelClassName="!w-[40rem]"
      containerClassName="!pb-2"
      confirmButtonClassName="!w-full !ml-0"
      titleClassName="text-lg"
      children={
        <div className="text-sm leading-5 font-normal text-gray-900">
          <h4 className="my-3 text-xs leading-4 font-semibold tracking-wider uppercase">
            Setting Up Google Authenticator
          </h4>
          <div className="mb-3 p-3 shadow border border-gray-50 rounded-md">
            <div className="flex items-center space-x-2">
              <span className="text-xs rounded-full py-1 px-2 bg-gray-100 text-gray-800 font-medium">
                Step 01
              </span>
              <span className="font-semibold">Download the App</span>
            </div>
            <p className="my-2">
              Download the Google Authenticator app on your mobile phone from
              the Google Play Store or Apple App Store.
            </p>
          </div>
          <div className="mb-3 p-3 shadow border border-gray-50 rounded-md">
            <div className="flex items-center space-x-2">
              <span className="text-xs rounded-full py-1 px-2 bg-gray-100 text-gray-800 font-medium">
                Step 02
              </span>
              <span className="font-semibold">
                Install Google Authenticator and open the App
              </span>
            </div>
          </div>
          <div className="mb-3 p-3 shadow border border-gray-50 rounded-md">
            <div className="flex items-center space-x-2">
              <span className="text-xs rounded-full py-1 px-2 bg-gray-100 text-gray-800 font-medium">
                Step 03
              </span>
              <span className="font-semibold">
                Add your EICARE staff account to Google Authenticator App
              </span>
            </div>
            <div className="my-2">
              <ul className="list-disc list-inside">
                <li>Tap on the + button and select Scan QR code</li>
                <li>
                  <span>Scan the QR code below</span>
                  <div>
                    <img
                      security="restricted"
                      src={twoFaSetup?.dataURL}
                      alt="QR Code"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-3 shadow border border-gray-50 rounded-md">
            <div className="flex items-center space-x-2">
              <span className="text-xs rounded-full py-1 px-2 bg-gray-100 text-gray-800 font-medium">
                Step 04
              </span>
              <span className="font-semibold">One last step</span>
            </div>
            <div className="my-2">
              <ul className="list-disc list-inside">
                <li>
                  <span className="my-3 text-sm leading-4">
                    After scanning the QR code, you will see a 6 digit number
                    next to your email address, add it below and click the
                    'Activate' button below.
                  </span>
                  <Input
                    label=""
                    type="text"
                    placeholder="Enter 6-digit authentication code"
                    value={sixDigitCode}
                    onChange={(e) => {
                      setSixDigitCode(e.target.value);
                      setError({ ...error, sixDigitCode: null });
                    }}
                    error={error.sixDigitCode}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      open={openDialogue}
      handleConfirm={handle2FALogin}
      iconElement={
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-12 sm:w-12 my-4">
          <Icon name="lock" className="h-6 w-6" />
        </div>
      }
    />
  );
}
