import Modal from "components/Modal";

interface AnnualAppraisalReferenceGuideProps {
  showReferenceGuide: boolean;
  setShowReferenceGuide: (show: boolean) => void;
}
const AnnualAppraisalReferenceGuide: React.FC<
  AnnualAppraisalReferenceGuideProps
> = ({ showReferenceGuide, setShowReferenceGuide }) => (
  <Modal
    isOpen={showReferenceGuide}
    onClose={() => setShowReferenceGuide(false)}
    modalHeader=""
    className="!bg-white"
    modalBodyClassName="!mt-0 pb-6"
    customModalStyles={{ width: "1216px" }}
  >
    <h3 className="text-lg leading-6 font-semibold text-gray-900 px-6 pt-2 pb-5 border-b border-gray-300">
      Annual Appraisal Guide
    </h3>
    <div className="px-6 py-5">
      <div className="flex">
        <h3 className="w-1/4 text-sm leading-5 font-semibold text-gray-900">
          Capability
        </h3>
        <h3 className="w-3/4 text-sm leading-5 font-semibold text-gray-900">
          Expectation
        </h3>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Values
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - Integrity, do what we say we will do and consistently uphold the
            established values of our servies.
          </p>
          <p>
            - Empowerment, encourage autonomy and self-determination in either
            our staffs or our clients.
          </p>
          <p>
            - Independent, for staff to perform autonomously during their work.
          </p>
          <p>- Transparency, to staffs and clients.</p>
          <p>- Openness, for ideas, concerns or even criticisms.</p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Communicating
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - Demonstrates awareness of the impact own words and actions have on
            others
          </p>
          <p>- Responds promptly and appropriately to others</p>
          <p>- Displays tact and compassion when communicating with others</p>
          <p>
            - Uses appropriate methods of communication for the purpose and
            context
          </p>
          <p>- Openly shares information with others</p>
          <p>- Communicates in a clear and concise manner</p>
          <p>- Keeps accurate and complete records</p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Training
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - All mandatory competencies and training is kept up to date and not
            expired.
          </p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Delivering results
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>- Takes responsibility for meeting own work plan</p>
          <p>- Demonstrates quality and accuracy in all work</p>
          <p>
            - Demonstrates persistence to complete tasks despite setbacks or
            obstacles
          </p>
          <p>
            - Where needed, asks for help with work priorities, responsibilities
            and standards
          </p>
          <p>
            - Prioritises and monitors own work, and alters supervisor to issues
            when needed
          </p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Punctuality
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>- Attendance & punctuality supports the clients</p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Appearance
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - Apperance & personal hygiene & neat clean clothes matches the
            dress code and presents a professional image of EICARE.
          </p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Client centred care
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - Demonstrates understanding of how the organisational environment
            contributes to consumer care
          </p>
          <p>
            - Demonstrates understanding of how own work impacts on the consumer
            experience
          </p>
          <p>
            - Conducts work activities in ways that support the effective
            provision of services
          </p>
          <p>
            - Utilises opportunities to involve clients in the planning and
            provision of services
          </p>
          <p>
            - Demonstrates the company’s valines when interacting with clients
          </p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Adaptability & resilience
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>
            - Responds constructively when presented with new ideas or
            approaches
          </p>
          <p>- Demonstrates openness to modifying one’s approach when needed</p>
          <p>
            - Conducts work activities in ways that support the effective
            provision of services
          </p>
          <p>
            - Contributes actively and positively to the implementation of
            change
          </p>
          <p>
            - Asks constructive questions to better understand the need for
            change and how to support change
          </p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Works safely
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>- Complies with mandatory safety policy and procedures</p>
          <p>
            - Summons immediate help for any emergency and takes appropriate
            action to contain it
          </p>
          <p>
            - Reports any issues at work that put health, safety and wellbeing
            at risk
          </p>
          <p>- Manages own work pressure to protect against burnout</p>
          <p>- Remains calm and focused under pressure</p>
          <p>- Preservers during and following challenges or setbacks.</p>
        </div>
      </div>
      <div className="flex items-start mt-5">
        <span className="w-1/4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
          Utilising Resources
        </span>
        <div className="w-3/4 text-sm leading-5 font-normal text-gray-900 flex flex-col">
          <p>- Uses and maintains resources responsibility</p>
          <p>
            - Uses time efficiently and prevents irrelevant distractions from
            disrupting work
          </p>
        </div>
      </div>
    </div>
  </Modal>
);

export default AnnualAppraisalReferenceGuide;
